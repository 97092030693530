import React from "react";

// Customizable Area Start

import { Box, Typography, TextField, Backdrop,IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Modal as BaseModal } from '@mui/base/Modal';


const DeleteBox = styled(Box)({
  maxWidth: "600px",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  "@media (max-width: 960px)": {
    maxWidth: "85%",

  },
  "& .typoText": {
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    textAlign: "left",
    color: "#C7161D"

  },
  "& .typoSubText": {
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    color: "#17171",
  },
  "& .passwordTypo": {
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#78716C",
  },
  "& .btn1": {
    color: "White",
    backgroundColor: "#C7161D",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    border: "none",
    cursor:"pointer"

  },
  "& .btn2": {
    color: "#171717",
    border: "1px solid #171717",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    textAlign: "center",
    cursor:"pointer",
    backgroundColor: "white"

  },
  "& .custom-text-field": {
    width: "100%",
  },

});
const Modal = styled(BaseModal)({
  position: "fixed",
  zIndex: "1300",
  inset: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "48px 49px 48px 49px",
  gap: "32px",
  opacity: "0px",
  top: "261px",
  left: "500px",

  "& .btn1": {
    color: "White",
    backgroundColor: "#C7161D",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    border: "none"
  },
  "& .btn2": {
    color: "#171717",
    border: "1px solid #171717",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    textAlign: "center",
    backgroundColor: "white"
  },

});

const StyledBackdrop = styled(Backdrop)({
  zIndex: "-1",
  position: "fixed",
  inset: 0,
  backgroundColor: "rgb(0 0 0 / 0.5)",
});



const ModalContent = styled('div')({

  fontFamily: "Quicksand",
  fontWeight: 500,
  textAlign: "start",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  overflow: "hidden",
  backgroundColor: '#fff',
  borderRadius: "8px",
  border: "1px solid grey",
  boxShadow: "0 4px 12px",
  padding: "24px",
  color: "#171717",


  " & .modal-title ": {
    margin: "0",
    marginBottom: "8px",
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    textAlign: "left",

  },
  "& .modal-description": {
    margin: "0",
    marginBottom: "4px",
    color: "#78716C",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",

  }
});

// Customizable Area End

import Settings2Controller, {
  Props,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDeleteScreen = () => {
    return (
      <>
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <DeleteBox>
            <Box>
              <Typography className="typoText" >Delete Account</Typography>
            </Box>
            <Box>
              <Typography className="typoSubText" >We recognize that there may be times when you'd like to close your account. If you decide to permanently delete your account and all related data, you can proceed here. Please be aware that this action is final and cannot be undone.</Typography>
            </Box>
            <Box>
              <div className="passwordTypo">
                Password
              </div>
              <TextField
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#E7E5E4",
                    fontFamily: "Quicksand",
                    "&:focus": {
                      border: "none",
                      boxShadow: "none",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                  },
                }}
                className="custom-text-field"
                placeholder="Your password"
                name="password"
                data-test-id="passwordId"
                value={this.state.setPassword}
                onChange={this.handleChange}
                type={this.state.showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: <IconButton
                    data-test-id="iconId"
                    onClick={this.handleShowPassword}
                    style={{ position: 'absolute', right: 0 }}
                  >
                    {this.state.showPassword ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                }}
              />
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", gap: "16px", }}>
              <button className="btn1" data-test-id ="delete-account" onClick={() => { this.setState({ openDeleteModal: !this.state.openDeleteModal }) }}> Delete Account</button>
            </Box>

            {this.state.openDeleteModal &&
              <Box>
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={this.handleClose}
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <ModalContent style={{ width: 414, gap: "32px", borderRadius: "16px", padding: "48px 49px 48px 49px" }}>
                    <h2 id="unstyled-modal-title" className="modal-title">
                      Are you sure you want to delete your account?
                    </h2>
                    <p id="unstyled-modal-description" className="modal-description">
                      Deleting your account is a permanent action. You will lose access to all your data, and it cannot be recovered. This action cannot be undone.
                    </p>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "16px", }}>
                      <button className="btn1" data-test-id ="delete-account1" onClick={() => { this.setState({ openDeleteModalsecond: !this.state.openDeleteModalsecond, openDeleteModal: false }) }}> Yes,Delete Account</button>
                      <button className="btn2" data-test-id ="delete-account2" onClick={() => { this.setState({ openDeleteModal: false }) }}> No, Go Back</button>
                    </Box>
                  </ModalContent>
                </Modal>
              </Box>
            }
            {this.state.openDeleteModalsecond &&
              <Box>
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={this.handleClose}
                  slots={{ backdrop: StyledBackdrop }}
                >
                  <ModalContent style={{ width: 439, gap: "32px", borderRadius: "16px", padding: "48px" }}>
                    <h2 id="unstyled-modal-title" className="modal-title">
                      Success: Your Account has been deleted! </h2>
                    <p id="unstyled-modal-description" className="modal-description">
                      We truly hope you’ll return to us soon, and we look forward to welcoming you back whenever you're ready!</p>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "16px", }}>
                      <button className="btn1" onClick={this.redirectOnLoginPage}> Go to Login</button>
                    </Box>
                  </ModalContent>
                </Modal>
              </Box>
            }
          </DeleteBox>
        </Box>

      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      this.renderDeleteScreen()
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
