import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { Box } from "@mui/material";
import { styled } from "@mui/styles";
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Carousel from "react-multi-carousel";



// Customizable Area End

import CfquotebuilderController, {
  Props,
  configJSON,
} from "./CfquotebuilderController";
import { logo } from "./assets";

export default class Cfquotebuilder extends CfquotebuilderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <MainBox>
        <CrouselBox >
          <Carousel
            data-test-id="carousel"
            className="carousel-container"
            swipeable={false}
            draggable={false}
            responsive={this.responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            arrows={false}
            keyBoardControl={true}
            partialVisible={true}
            customTransition="all 0.5"
            transitionDuration={500}
            showDots={true}
            dotListClass="custom-dot-list-style"
          >
            {this.state.carouselImages.map((data: any, index: number) => {
              return (
                <>
                  <img src={data} key={index} alt="carouselImages" className="login-main-image" />
                  <Box
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "linear-gradient(180deg, rgba(0, 0, 0, 0.5) 100%, rgba(0, 0, 0, 0) 0%)",
                    }}
                  />
                </>
              )
            })}

          </Carousel>
          <Logo>
            <img src={logo} style={{ width: "100%" }} />
          </Logo>
          <HeaderTypo>
            Battery Concepts International
          </HeaderTypo>
          <ButtonBoxCrousel>
            <button className="btn1" data-test-id="account1" onClick={() => { this.handleIncrement() }}> Next
              <ArrowForwardOutlinedIcon />
            </button>
          </ButtonBoxCrousel>
        </CrouselBox>
        <QuotesBox>
          <OuotesDataBox>
            <Typo1 style={{ fontWeight: "700" }}>
              {this.state.carouselDataItems[this.state.increaseDataItems].name}
            </Typo1>
            <hr style={{ color: "#C7161D", width: "100%" }} />
            <Box style={{ width: "295px" }}>
              <Typo2 style={{ fontWeight: "700" }}>
                {this.state.carouselDataItems[this.state.increaseDataItems].info}
              </Typo2>
            </Box>
          </OuotesDataBox>
          <ButtonBox>
            <button className="btn1" data-test-id="account2" onClick={() => { this.handleIncrement() }}> Next
              <ArrowForwardOutlinedIcon />
            </button>
          </ButtonBox>
        </QuotesBox>
      </MainBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Typo1 = styled(Typography)({
  fontFamily: "Quicksand !important",
  fontSize: "40px !important",
  textAlign: "left" as 'left',
  color: "#ffffff",
  "@media (max-width:960px)": {
    fontFamily: "Quicksand !important",
    fontSize: "32px !important",
  }
})
const Typo2 = styled(Typography)({
  fontFamily: "Quicksand !important",
  fontSize: 18,
  textAlign: "left" as 'left',
  color: "#ffffff",
  "@media (max-width:960px)": {
    fontFamily: "Quicksand !important",
    fontSize: 16,
  }
});

const CrouselBox = styled(Box)({
  width: "50%",
  height: "100%",
  position: "relative",
  "@media (max-width:960px)": {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  "& .login-main-image": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    filter: "grayscale(100%)"
  },
  "& .react-multi-carousel-list": {
    height: "100%",
    alignItems: "normal"
  },
  "& .react-multi-carousel-dot-list": {
    bottom: "12%"
  },
  "& .react-multi-carousel-dot button": {
    borderColor: "transparent",
    background: "#78716C",
    zIndex: 2,
    position: "relative"
  },
  "& .react-multi-carousel-dot--active button": {
    background: "#C7161D!important",
    width: "12px",
    height: "12px",
    zIndex: 2,
    position: "relative"
  },

});

const MainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  backgroundColor: "black",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  "@media (max-width:960px)": {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
});
const Logo = styled(Box)({
  position: "absolute",
  left: "50%",
  top: "10%",
  transform: "translate(-50%,-50%)",
  maxWidth: "262px",
  "@media (max-width:960px)": {
    width: "226px",
    flexDirection: "column",
  },
});
const HeaderTypo = styled(Typography)({
  fontFamily: "Quicksand !important",
  color: "white",
  fontSize: "18px !important",
  fontWeight: "700 !important",
  lineHeight: "22.5px !important",
  position: "absolute",
  top: "18%",
  left: "35%",
  "@media (max-width:960px)": {
    fontSize: "18px !important",
    fontWeight: "700 !important",
    whiteSpace: "nowrap",
    top: "18%",
    left: "50%",
    textAlign: "center",
    transform: "translate(-50%,-50%)",

  },
});
const QuotesBox = styled(Box)({
  width: "50%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "@media (max-width:960px)": {
    width: "100%",
    height: "100%",
    position: "absolute"
  },
});
const OuotesDataBox = styled(Box)({
  width: "343px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "32px 24px",
  "@media (max-width:960px)": {
    opacity: "0.8",
    backgroundColor: "black",
    borderRadius: "16px",
    position: "absolute"

  },
});
const ButtonBox = styled(Box)({
  width: "50%",
  textAlign: "end",
  display: "flex",
  justifyContent: "end",
  "@media (max-width:960px)": {
    display: "none",
  },
  "& .btn1": {
    color: "White",
    backgroundColor: "#C7161D",
    borderRadius: "8px",
    height: "56px",
    width: "160px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})

const ButtonBoxCrousel = styled(Box)({
  width: "50%",
  textAlign: "end",
  position: "absolute",

  "@media (max-width:960px)": {
    position: "absolute",
    bottom: "20px",
    textAlign: "end",
    display: "visible",
    right: "3px",
    zIndex: 1
  },
  "& .btn1": {
    color: "White",
    backgroundColor: "#C7161D",
    borderRadius: "8px",
    height: "56px",
    width: "160px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})
// Customizable Area End
