Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelFullName = "Full Name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.emailAccountRegisterAPiEndPoint ="account_block/api/v1/sign_up";

exports.postApiMethod = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.theCodeHasBeenResentText="The code has been resent.";
exports.wrongCodePleaseTryAgainText="Wrong Code, please try again";
exports.requiredField="*required field";
exports.nameRegex= /^[a-zA-Z ]+$/;
exports.phoneRegex=/^(?:\+?\d{1,3}[- ]?)?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/;
exports.emailRegex= /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.placeRegex=/^[a-zA-Z0-9\s.'-]+$/;
exports.invalidFullName="invalid full name";
exports.invalidPhone="invalid phone";
exports.invalidEmail="invalid email";
exports.invalidPlace="invalid place";
exports.profile="Profile";
exports.uploadImage="Upload Image";
exports.signUp="Sign up";
exports.enterCode="Enter Code";
exports.verify="Verify";
exports.goToLogin="Go to Login"
exports.atLeastOneCapitalRegExp= /^(?=.*[A-Z])/
exports.atLeastOneSmallRegExp=/^(?=.*[a-z])/
exports.atLeastOneNumberRegExp = /^(?=.*\d)/
exports.pwdRegExp=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.atLeastOneCapital="At least one capital letter" ;
exports.atLeastOneSmall="At least one lowercase letter";
exports.atLeastOneNumber="At least one number"
exports.minEightLength="Minimum character length is 8 characters";
exports.iAgreeWithText=" I agree with ";
exports.termsAndConditionsText=" Terms and Conditions ";
exports.andText=" and ";
exports.privacyPolicy=" Privacy Policy ";
exports.profilePictureOptional="Profile Picture (optional)";
exports.pleaseCheckYourEmailText="Please check your email";
exports.welComeText="Welcome to Battery Concepts International, Inc. BCI.";
exports.didYouText="Did you not receive the code?";
exports.resendCode="Resend Code";
exports.theCodeHasBeenResent="The code has been resent.";
exports.wrongCodePleaseTryAgainText="Wrong Code, please try again";
exports.weWillNotifyText="We’ll notify you once your account has been approved."
exports.enterCodeDescription="Please enter the code that should have arrived in your email."
exports.checkYourText="Check your email and follow the instructions to confirm your email to activate your accont."
exports.youHaveToAgreeText="You have to agree with Privacy Policy and Terms and Conditions to Sign up.";
exports.thankYouText="Thank you for completing the signup process! Your account is now under review by our approval team."
exports.thisMayTakeText="This may take a little time, but rest assured, we’re working to get you up and running as soon as possible."
// Customizable Area End
