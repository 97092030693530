// Customizable Area Start
import React from "react";

import {
  Box,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  TextareaAutosize,
  IconButton,

} from "@mui/material";
import { styled } from "@mui/styles";
import { Formik, FormikTouched } from "formik";
import * as Yup from "yup";
import Checkbox from '@mui/material/Checkbox';
import Navbar from "../../../../packages/components/src/NavBar.web";
import AccesoriesController, {
  Props,
  configJSON,
} from "./AccesoriesController.web";
import { Close, Image } from "@mui/icons-material";
import { activeUpload, messageImg, upload } from "./assets";

export default class Accesories extends AccesoriesController {
  constructor(props: Props) {
    super(props);
  }

  isSchema = () => {
    return Yup.object().shape({
      shiftOne: Yup.string().required('*required field'),
    });
  };

  submit = (values: any, handler: any) => {
    this.setState({
      initialValues: {
        Answer: values.Answer,
        months: values.months,
      }
    })
    handler.resetForm();
  }

  renderSelectOption = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any, setFieldValue: any) => {
    const {
      profilePictureFile,
    } = this.state
    return (
      <>
        <Box style={{ display: "flex", flexDirection: "column", gap: "16px", width: "343px" }}>
          <Typography sx={webStyle.questiontypo}>
            Please select the Accesories
          </Typography>
          <CheckBoxMainBox>
            {this.state.staticCheckBoxData.map((data, index) => {
              return (
                <>

                  <FormGroup key={index}
                    data-test-id="testcase1"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-test-id="testcase1"
                          checked={this.isChecked(data.name, values.months)}
                          onChange={this.handleCheckboxChange.bind(this, data.name, setFieldValue, values.months)}
                          sx={{
                            '&.Mui-checked': {
                              color: "#C7161D",
                            },
                          }}
                        />
                      }
                      name='months'
                      label={
                        <Typography style={webStyle.checkBoxLabel}>
                          {data.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </>
              )
            })}
          </CheckBoxMainBox>

          <hr style={webStyle.hrClasss} />

          <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography style={webStyle.lookingForText}>
              Can't find what you're looking for?
            </Typography>
            <Typography style={{
              fontFamily: "Quicksand",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              borderRadius: "8px",
              color: "#78716C",
            }}>
              Enter the name or characteristics of the product:
            </Typography>
            <TextareaAutosize
              style={webStyle.textAutoSizeStyle}
              placeholder="Answer"
              name="Answer"
              data-test-id="passwordId"
              value={values.Answer}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <hr style={webStyle.hrClasss} />

          <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Typography style={webStyle.relevantPictureStyle}>
              Relevant Pictures
            </Typography>
            <Typography style={webStyle.attchPhotos}>
              Attach photos of existing battery or relevant pictures for qupte
            </Typography>

            <Box sx={webStyle.inputBox}>
              <Box sx={webStyle.uploadBox}>
                <TextField
                  onChange={this.handleProfilePicture}
                  id="uploadImage"
                  sx={webStyle.fileInput}
                  fullWidth
                  type="file"
                  data-test-id="profile-picture"
                />
                <IconButton component={"label"} htmlFor="uploadImage">
                  <Box component={"img"}
                    src={this.conditionFunc(Boolean(profilePictureFile), activeUpload, upload)} sx={webStyle.uploadImage} />
                </IconButton>
                <Typography sx={webStyle.uploadText}>{configJSON.uploadImage}</Typography>
              </Box>
            </Box>
            {this.conditionIcon(Boolean(profilePictureFile), <Box sx={webStyle.imageNameMainBox}>
              <Box sx={webStyle.imageBox}>
                <Image sx={webStyle.imageIcon} />
                <Typography sx={webStyle.imageName} data-testid="profile-picture-name">{profilePictureFile?.name}</Typography>
              </Box>
              <IconButton data-test-id="remove-profile-picture" onClick={this.handleRemoveUploadedImage}>
                <Close sx={webStyle.close} />
              </IconButton>
            </Box>, <></>)}
          </Box>
        </Box >

      </>
    )

  }



  render() {

    return (
      <>
        <Navbar navigation={null} id="" />
        <ClientDetailBox>
          <Formik
            data-test-id="userboxForm"
            initialValues={this.state.initialValues}
            validationSchema={this.isSchema()}
            onSubmit={this.submit}
          >
            {({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
              return (

                <Box className="formclass" >
                  <Box style={{ width: "343px" }}>
                    <Typography style={webStyle.headingtypo}>Accesories</Typography>
                  </Box>
                  <Box style={webStyle.messageBox}>
                    <Box>
                      <img src={messageImg} />
                    </Box>
                    <Box style={{ width: "98%", textAlign: "center" }}>
                      <Typography style={webStyle.messageTexttypo}>
                        If you do not see the accessory you need, please contact the salesman will contact admin for pricing
                      </Typography>
                    </Box>
                  </Box>
                  {this.renderSelectOption(errors, touched, values, handleChange, handleBlur, setFieldValue)}
                  <Box style={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "343px", width: "100%" }}>
                    <button type="submit" className="btn1" data-test-id="delete-account1" onClick={() => handleSubmit()} > Continue</button>
                    <button className="btn2" data-test-id="delete-account2">Back</button>
                  </Box>
                </Box>
              )
            }}
          </Formik>
        </ClientDetailBox >
      </>
    );
  }
}

const webStyle = {
  relevantPictureStyle: {
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    color: "#171717",
  },
  attchPhotos: {
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius: "8px",
    color: "#78716C",
  },
  textAutoSizeStyle: {
    height: "112px",
    width: "100%",
    minHeight: "112px",
    maxHeight: "112px",
    minWidth: "100%",
    maxWidth: "100%",
    backgroundColor: "#E7E5E4",
    resize: "none" as "none",
    border: "none !important",
    borderRadius: "8px",
    padding: "1rem",
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Quicksand",
  },
  questiontypo: {
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#78716C",
    textAlign: "left",
  },
  lookingForText: {
    fontFamily: "Quicksand",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "28px",
    color: "#171717",
  },
  messageBox: {
    width: "343px",
    borderLeft: "2px solid #455992", borderRight: "2px solid #455992",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    padding: "16px",
    gap: "10px"
  },
  messageTexttypo: {
    fontFamily: "Quicksand",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  headingtypo: {
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
  },
  inputBox: {
    width: "100%",
    mb: "4%"
  },
  inputLabelBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mb: 0.5,
  },
  inputName: {
    fontWeight: 700,
    color: "#78716C",
  },
  uploadBox: {
    border: "1px dashed #B4B4B4",
    height: "52px",
    p: "8px",
    borderRadius: "8px",
    gap: "2px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  uploadText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#78716C",
  },
  imageNameMainBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  fileInput: {
    display: "none",
  },
  uploadImage: {
    width: "36px",
    height: "36px",
  },
  imageBox: {
    display: "flex",
    alignItems: "center",
    gap: "2%",
    width: "100%"
  },
  imageIcon: {
    color: "#C7161D",
    fontSize: "22px"
  },
  imageName: {
    fontSize: "10px",
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  close: {
    color: "#C7161D"
  },
  checkBoxLabel: {
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "13.79px",
    color: "#171717"
  },

  hrClasss: {
    width: "100%",
    color: "#D6D3D1",
    margin: "2rem 0rem"

  }

};

const CheckBoxMainBox = styled(Box)({
  // height: "252px",
  // display: "flex",
  // flexWrap: "wrap",
  // flexDirection: "column"
})

const ClientDetailBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  gap: "48px",
  marginTop: "3rem",

  "& .formclass": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "48px",
  },

  "& .btn1": {
    color: "White",
    backgroundColor: "#C7161D",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    border: "none"
  },
  "& .btn2": {
    color: "#171717",
    border: "1px solid #171717",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    textAlign: "center",
    backgroundColor: "white"
  },

})



// Customizable Area End
