export const userProfile = require("../assets/user-profile.png");
export const headerImage = require("../assets/image.png");
export const createClientImage = require("../assets/createClientImage.png");
export const previousQuotesImage = require("../assets/previousQuotesImage.png");
export const analyticsImage = require("../assets/analyticsImage.png");
export const bciImage = require("../assets/bci.png");
export const support = require("../assets/support.png");
export const createClientIcon = require("../assets/createClient.png");
export const previousQoutesIcon = require("../assets/previousQoutes.png");
export const analyticsIcon = require("../assets/analytics.png");
export const avatar = require("../assets/avtar.png");






