import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemButton,
} from "@mui/material";
import { styled } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { analyticsIcon, avatar, bciImage, createClientIcon, headerImage, previousQoutesIcon, support } from "./assets";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';


// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DrawerList = () => (
    <DrawerMainBox role="presentation" onClick={() => this.toggleDrawer(false)}>
      <Box style={{ textAlign: "end" }}>
        <CloseIcon style={{ color: "#ffffff" }} />
      </Box>
      <DrawerLogo>
        <img src={bciImage} style={{ width: "40%" }} />
      </DrawerLogo>

      <UserDataBox>
        <AvtarBox>
          <img src={avatar} style={{}} />

        </AvtarBox>
        <UserInfo>
          <Typography style={{
            fontFamily: "Cerebri Sans",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "17.78px",
            textAlign: "left",
            color: "#ffffff"

          }}>
            Claire Jones
          </Typography>
          <Typography style={{
            fontFamily: "Cerebri Sans",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "15.24px",
            textAlign: "left",
            color: "#A8A29E"
          }}>
            claire.jones@email.com
          </Typography>
        </UserInfo>
      </UserDataBox>

      <List>
        {this.drawerStaticData1.map((text, index) => (
          <ListItem
            style={{ color: "white" }}
            key={text.title} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {this.renderImage(text.title)}
              </ListItemIcon>
              <CustomTextBox primary={text.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider style={{ backgroundColor: "grey" }} />
      <List>
        {this.drawerStaticData2.map((text, index) => (
          <ListItem
            style={{ color: "white" }}
            key={text.title} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {this.renderImage(text.title)}
              </ListItemIcon>
              <CustomTextBox primary={text.title} />
            </ListItemButton>
          </ListItem>
        ))}
        <Button style={{
          border: "1px solid grey",
          width: "100%",
          color: "#ffffff",
          textTransform: "capitalize",
          display: "flex",
          justifyContent: "flex-start",
          gap: "16px",
          padding: "8px 16px",
          marginTop: "3rem",
          fontFamily: "Quicksand",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "20px",
        }}>
          <LogoutOutlinedIcon />
          Log Out</Button>

      </List>
    </DrawerMainBox>
  );


  renderImage = (title: string) => {
    switch (title) {
      case "Create Client":
        return <img src={createClientIcon} />;
      case "Previous Quotes":
        return <img src={previousQoutesIcon} />;
      case "Analytics":
        return <img src={analyticsIcon} />;
      case "Notifictions":
        return <NotificationsNoneIcon style={{ color: "#ffffff" }} />;
      case "Legal":
        return <DescriptionOutlinedIcon style={{ color: "#ffffff" }} />;
      case "Settings":
        return <SettingsOutlinedIcon style={{ color: "#ffffff" }} />;
      case "Support":
        return <img src={support} />;
      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Box style={{ display: "flex", flexDirection: "column"}}>
          <ImageHeaderBox>
            <img src={this.props.srcURL ? this.props.srcURL : headerImage} style={{ width: "100%", height: "100%" }} />
            <Box
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            />


            <MenuIconBox onClick={() => this.toggleDrawer(true)}>
              <MenuIcon />
            </MenuIconBox>
            <BellIconBox>
              <NotificationsNoneIcon />
            </BellIconBox>
            <Logo>
              <img src={bciImage} style={{ width: "100%" }} />
            </Logo>
            <HeaderTypo>
              {this.props.typo? this.props.typo:""}
            </HeaderTypo>
          </ImageHeaderBox>

          <Box>
            <Drawer
              open={this.state.open} onClose={() => this.toggleDrawer(false)}>
              {this.DrawerList()}
            </Drawer>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ImageHeaderBox = styled(Box)({
  width: "100%",
  borderRadius: "0px 0px 0px 32px",
  opacity: "1",
  position: "relative",
  overflow: "hidden",
  display: "none",
  "@media (max-width:960px)": {
    display: "block",
    width: "100%",
    borderRadius: "0px 0px 0px 32px",
    opacity: "1",
    position: "relative",
    overflow: "hidden",
    height:"160px"

  },
});

const MenuIconBox = styled(Box)({
  position: "absolute",
  top: "18%",
  left: "2%",
  color: "white",
  cursor: "pointer",
  zIndex: '1000',
});
const BellIconBox = styled(Box)({
  position: "absolute",
  top: "18%",
  right: "2%",
  color: "white",
  cursor: "pointer",
  zIndex: '1000',
  textAlign: "right"


});

const Logo = styled(Box)({
  position: "absolute",
  left: "50%",
  top: "25%",
  transform: "translate(-50%,-50%)",
  width: "20%"
});

const DrawerMainBox = styled(Box)({
  width: 350,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  backgroundColor: "black",
  padding: "1rem"
});


const DrawerLogo = styled(Box)({
  textAlign: "center"
});

const UserDataBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px"
});

const AvtarBox = styled(Box)({
});

const UserInfo = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "1px"
});

const HeaderTypo = styled(Typography)({
  fontFamily: "Quicksand !important",
  color: "white",
  fontSize: "18px !important",
  fontWeight: "700 !important",
  lineHeight: "22.5px !important",
  position: "absolute",
  textAlign: "center",
  width: "100%",
  top: "73%",
  "@media (max-width:960px)": {
    fontSize: "10px !important",
    fontWeight: "400 !important",
    top: "60%",

  },
});

const CustomTextBox = styled(ListItemText)({
  "& .MuiTypography-root": {
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
  }
});

const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
};
// Customizable Area End
