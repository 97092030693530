export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const  belowBannerImage =require( "../assets/belowBannerImage.png");
export const  aboveBannerImage =require( "../assets/aboveBannerImage.png");
export const logo=require("../assets/logo.png");
export const mobileBelowImage=require("../assets/mobileBelowImage.png");
export const upload = require("../assets/upload.png");
export const activeUpload = require("../assets/activeUpload.jpg");
export const unCheckedBox = require("../assets/unCheckedBox.png");
export const checkedBox = require("../assets/checkedBox.png");
