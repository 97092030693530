export const speedPrev = require('../assets/speedPrev.png');
export const speedNext = require('../assets/speedNext.png');
export const theHindu = require('../assets/theHindu.png');
export const economy = require('../assets/economy.png');
export const previous = require('../assets/previous.png');
export const next = require('../assets/next.png');
export const speed = require('../assets/speed.png');
export const play = require('../assets/play.png');
export const bbcHindi = require('../assets/bbcHindi.png');
export const national = require('../assets/national.png');
export const home = require('../assets/home.png');
export const bellIcon = require('../assets/bellIcon.png');
export const navIcon = require('../assets/navIcon.png');
export const search = require('../assets/search.png');
export const logo = require('../assets/logo.png');
export const appLogo = require('../assets/appLogo.png');
export const share = require('../assets/share.png');
export const save = require('../assets/save.png');
export const addToPlaylist = require('../assets/addToPlaylist.png');
export const backArrow = require('../assets/backArrow.png');
export const backIcon = require('../assets/backIcon.png');
export const mediaImage = require('../assets/mediaImage.png');
export const bciImage = require("../assets/bci.png");
export const support = require("../assets/support.png");
export const createClientIcon = require("../assets/createClient.png");
export const previousQoutesIcon = require("../assets/previousQoutes.png");
export const analyticsIcon = require("../assets/analytics.png");
export const avatar = require("../assets/avtar.png");
export const activeUpload = require("../assets/activeUpload.png");
export const upload = require("../assets/upload.png");
export const messageImg = require("../assets/message.png");
export const mobileImgCreateClint = require("../assets/mobileImgCreateClint.png");
export const previousHistory = require("../assets/previousHistory.png");
export const previousQuotesMobileImg = require("../assets/previousQuotesMobileImg.png");