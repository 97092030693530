import React from "react";

// Customizable Area Start
import {
  Box,
  Button, 
  Typography,
  CssBaseline,
  IconButton,
  createTheme,
  ThemeProvider,
  CircularProgress,
  } from "@mui/material";
 import { ArrowBack } from '@mui/icons-material';
 import {aboveBannerImage,belowBannerImage,logo,mobileBelowImage} from "./assets"
 import parse from "html-react-parser"
  const theme = createTheme({
    typography:{
      fontFamily:"Quicksand"
    }});
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {termsAndConditionData,loadingStatus}=this.state
      return (
       <ThemeProvider theme={theme}>
         <CssBaseline />
         <Box sx={webStyles.mainBox}>
           <Box sx={webStyles.bannerImageBox}>
             <IconButton sx={webStyles.iconButton} onClick={this.handleNavigateToSignUp}>
               <ArrowBack sx={webStyles.icon} />
             </IconButton>
             <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={webStyles.mobileBannerImage} />
             <Box component={"img"} src={logo} sx={webStyles.logoImage} />
             <Box
               component={"img"}
               src={belowBannerImage}
               alt="Banner image"
               sx={webStyles.webBelowImage}
             />
             <Box
               component={"img"}
               src={aboveBannerImage}
               sx={webStyles.webAboveImage}
             />
           </Box>
           <Box sx={webStyles.contentMainBox}>
             <Box sx={webStyles.contentBox}>
               <Typography sx={webStyles.heading} data-testid="heading">
                 {configJSON.termsAndConditions}
               </Typography>
                {(() => {
                  switch (loadingStatus) {
                    case "PENDING":
                      return <Box sx={webStyles.errorText}>
                        <CircularProgress />
                      </Box>
                    case "SUCCEEDED":
                      return <Box data-test-id="data-box" sx={webStyles.scrollBox}>
                        {parse(termsAndConditionData)}
                      </Box>
                    case "FAILED":
                      return <Typography data-test-id="error-text" sx={webStyles.errorText}>{termsAndConditionData}</Typography>
                  }
                })()}
                <Box sx={webStyles.backButtonBox}>
                  <Button
                   data-test-id="second-back-button"
                   startIcon={<ArrowBack sx={webStyles.icon} />}
                   sx={webStyles.backButton}
                   onClick={this.handleNavigateToSignUp}
                 >
                   {configJSON.back}
                 </Button>
               </Box>
             </Box>
           </Box>
         </Box>
       </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webCommonScrollbar={
  pr: "2px",
  [theme.breakpoints.up("md")]: {
    height: "80%",
    width: "100%%",
    my: "10px",
    
  },
  [theme.breakpoints.up("sm")]: {
    height: "75%",
    width: "100%",
  },
  [theme.breakpoints.up("xs")]: {
    height: "70%",
    width: "100%",
  },
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "12px",
    backgroundColor: "#f5f5f5",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#D5D4D2",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
    backgroundColor: "#B02026",
  },
  scrollbarWidth: "thin",
  scrollbarColor: "#B02026 #D5D4D2",
}
const webStyles = {
  
  bannerImageBox: {
    position: "relative",
    borderRadius: "10px",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "50%",
      borderRadius: "20px",
      overflow: "hidden"
    },
    [theme.breakpoints.up("sm")]: {
      height: "220px",
      width: "100%",
      borderRadius: "30px 30px 0px 0px",
      overflow: "hidden"
    },
    [theme.breakpoints.up("xs")]: {
      height: "160px",
      width: "100%",
      borderRadius: "30px 30px 0px 0px",
      overflow: "hidden"
    },
  },
  backButton: {
    "&.MuiButtonBase-root": {
      bgcolor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#BE1821",
      textTransform: "none",
      fontWeight: 900,
      fontSize: 20,
    },
  },
  webAboveImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "block"
    },
    [theme.breakpoints.between("xs", "md")]: {
      objectPosition: "top",
      display: "none"
    },
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block"
    },
  },
  scrollBox: {...webCommonScrollbar},
  mainBox: {
    display: "flex",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
  },
  contentMainBox: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "10px",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "50%",
      alignItems: "center",
    },
    [theme.breakpoints.up("xs")]: {
      height: "80%",
      width: "100%",
      alignItems: "start",
    },
  },
  bannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "25px",
    },
    [theme.breakpoints.between("xs", "md")]: {
      borderRadius: "25px 25px 0px 0px",
    },
  },
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: 'translate(-50%, 50%)',
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none"
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block"
    },
  },
  contentBox: {
    [theme.breakpoints.up("md")]: {
      height: "80%",
      width: "375px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      width: "372px",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      width: "100%",
      p: "10px"
    },
  },
  errorText: {
    ...webCommonScrollbar,
    display: "flex",
    justifyContent: "center",
    alignItems:"center",
    color:"#B02026",
  },
  iconButton: {
    position: "absolute",
    color: "white",
    left: 15,
    top: 30,
    zIndex:3,
    display:"none",
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
  icon: {
    fontSize: 30,
  },
  heading: {
    fontWeight: 700,
    fontSize: "32px",
    pb: 1,
    [theme.breakpoints.up("md")]: {
      p: "0px"
    },
    [theme.breakpoints.up("xs")]: {
      pt: "40px",
      pb: "20px"
    },
  },
  backButtonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pt: 2,
  },
  webBelowImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "block"
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "none"
    },
  },
};

// Customizable Area End
