import React from "react";

import {
  Box, // Customizable Area Start
  TextField,
  // Customizable Area End
  Container,
  Typography,
} from "@mui/material";

// Customizable Area Start
import {
  Theme,
} from "@mui/material/styles";
import { styled } from "@mui/styles";
import { Formik, FormikTouched } from "formik";
import * as Yup from "yup";

import SurveysController, {
  Props,
} from "./SurveysController.web";
import "@mui/styles";
import Navbar from "../../../../packages/components/src/NavBar.web";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import { mobileImgCreateClint } from "./assets";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

// Customizable Area End

export default class Surveys extends SurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  isSchema = () => {
    return Yup.object().shape({
      clientName: Yup.string().required('*required field'),
      plantWarehouse: Yup.string().required('*required field'),
      phone: Yup.string().required('*required field').matches(/^[6-9]\d{9}$/,'Please enter valid phone number'),
      email: Yup.string().email('Invalid email').required('*required field'),
      pointofContact: Yup.string().required('*required field'),
      date: Yup.string().required('*required field'),
      branchContact: Yup.string().required('*required field'),
      branch: Yup.string().required('*required field'),

    });
  };

  submit = (values: any, handler: any) => {
    this.setState({
      initialValues: {
        clientName: values.clientName,
        plantWarehouse: values.plantWarehouse,
        phone: values.phone,
        email: values.email,
        pointofContact: values.pointofContact,
        date: values.date,
        branchContact: values.branchContact,
        branch: values.branch
      }
    })
    handler.resetForm();
    this.createClient()
  }

  renderTextfields = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
    return (
      <>
        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Client name
            {errors.clientName && touched.clientName ? (
              <div className="errorclass">
                {errors.clientName}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.clientName && touched.clientName && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Your Client Name"
            name="clientName"
            data-test-id="passwordId"
            value={values.clientName}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>
        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Plant / Warehouse
            {errors.plantWarehouse && touched.plantWarehouse ? (<div className="errorclass">
              {errors.plantWarehouse}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.plantWarehouse && touched.plantWarehouse && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Enter Plant or Warehouse"
            name="plantWarehouse"
            data-test-id="plantWarehouse"
            value={values.plantWarehouse}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>

        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Phone
            {errors.phone && touched.phone ? (<div className="errorclass">
              {errors.phone}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.phone && touched.phone && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Your Phone"
            name="phone"
            data-test-id="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>

        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Email
            {errors.email && touched.email ? (<div className="errorclass">
              {errors.email}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.email && touched.email && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Email"
            name="email"
            data-test-id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>
      </>
    )

  }

  renderOtherTextFields = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
    return (
      <>
        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Point of Contact
            {errors.pointofContact && touched.pointofContact ? (<div className="errorclass">
              {errors.pointofContact}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.pointofContact && touched.pointofContact && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Point of Contact"
            name="pointofContact"
            data-test-id="passwordId"
            value={values.pointofContact}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>

        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Date
            {errors.date && touched.date ? (<div className="errorclass">
              {errors.date}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.date && touched.date && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="mm/dd/yyy"
            name="date"
            data-test-id="passwordId"
            value={values.date}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>

        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Branch Contact
            {errors.branchContact && touched.branchContact ? (<div className="errorclass">
              {errors.branchContact}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.branchContact && touched.branchContact && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Branch Contact"
            name="branchContact"
            data-test-id="passwordId"
            value={values.branchContact}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />
        </Box>

        <Box className="custom-text-field">
          <Box sx={webStyle.passwordTypo}>
            Branch
            {errors.branch && touched.branch ? (<div className="errorclass">
              {errors.branch}</div>) : null}
          </Box>
          <TextField
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                backgroundColor: "#E7E5E4",
                fontFamily: "Quicksand",
              },
              ...(errors.branch && touched.branch && {
                "& fieldset": {
                  border: "1px solid red !important",
                  borderColor: "#C7161D !important",
                  fontFamily: "Quicksand !important",
                },
              }),

              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",

              },
            }}
            placeholder="Branch"
            name="branch"
            data-test-id="passwordId"
            value={values.branch}
            onChange={handleChange}
            onBlur={handleBlur}
            variant="outlined"
          />

        </Box>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Navbar data-test-id="testcase1" navigation={this.props.navigation} navigateToAnyPage={this.navigateToAnyPage} id="" />
        <NavigationMenu 
                 navigation={null}
                 id=""
                 srcURL={mobileImgCreateClint}
                 />
       <Container>
        <ClientDetailBox>
          <Box className="custom-text-field">
            <Typography style={{
              fontFamily: "Quicksand",
              fontSize: "32px",
              fontWeight: 700,
              lineHeight: "40px",
              textAlign: "left"
            }}>Create Client</Typography>
          </Box>
          <Formik
            data-test-id="userboxForm"
            initialValues={this.state.initialValues}
            validationSchema={this.isSchema()}
            onSubmit={this.submit}
          >
            {({ values, errors, touched, handleSubmit, handleChange, handleBlur }) => {
              return (

                <Box className="formclass" >
                  {this.renderTextfields(errors, touched, values, handleChange, handleBlur)}
                  {this.renderOtherTextFields(errors, touched, values, handleChange, handleBlur)}

                  <Box style={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "343px", width: "100%" }}>
                    <button type="submit" className="btn1" data-test-id="delete-account1" onClick={() => handleSubmit()} > Continue to Survey</button>
                    <button className="btn2" data-test-id="delete-account2">Back</button>
                  </Box>
                </Box>
              )
            }}
          </Formik>
        </ClientDetailBox >
        </Container>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  passwordTypo: {
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    color: "#78716C",
    display: "flex",
    justifyContent: "space-between"
  },
  logoutButton: {
    border: "1px solid #57534E",
    color: "#ffffff",
    textTransform: "capitalize",
    display: "flex",
    justifyContent: "flex-start",
    gap: "16px",
    padding: "8px 16px",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    borderRadius: "8px",
  },
  createClientBtn: {
    background: "#C7161D",
    display: "flex",
    gap: "15px",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  previousQoutesbtn: {
    display: "flex", gap: "15px",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    borderRadius: "8px",
    textTransform: "capitalize",
  },
  analyticsbtn: {
    display: "flex", gap: "15px",
    fontFamily: "Quicksand",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "20px",
    borderRadius: "8px",
    textTransform: "capitalize",
  }

};
const ClientDetailBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  marginTop: "3rem",
  gap: "24px",
  "& .formclass": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "24px",
  },
  "& .errorclass": {
    color: "#C7161D",
    fontFamily: "Quicksand",
    fontSize: "10px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left"
  },
  "& .custom-text-field": {
    width: "343px"
  },
  "& .btn1": {
    color: "White",
    backgroundColor: "#C7161D",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    border: "none"
  },
  "& .btn2": {
    color: "#171717",
    border: "1px solid #171717",
    borderRadius: "8px",
    height: "55px",
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    textAlign: "center",
    backgroundColor: "white"
  },
})
// Customizable Area End
