export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const hawker = require("../assets/hawker.png")
export const logo = require("../assets/logo.png");
export const download = require("../assets/download.png")
export const send= require("../assets/send.png");
export const edit=require("../assets/edit.png");
export const backgroundImage=require("../assets/backgroundImage.png");
export const gradientImage=require("../assets/gradientImage.png");
export const mobileBelowImage=require("../assets/mobileBelowImage.png")