import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import NavigationMenuBar from "../../../../packages/components/src/NavBar.web";
import {
  hawker,
  logo,
  download,
  send,
  edit,
  mobileBelowImage,
  backgroundImage,
  gradientImage
} from "./assets"
import {
  Box,
  createTheme,
  ThemeProvider,
  CssBaseline,
  Typography as TYpography,
  Button as BUtton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material"
import {
  KeyboardArrowRight
} from "@mui/icons-material"

const theme = createTheme({
  typography: {
    fontFamily: "Quicksand"
  },
})
const bannerComponent=()=>{
  return <Box sx={styles.imageBox}>
  <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={styles.mobileBannerImage} />
  <Box component={"img"} src={logo} sx={styles.logoImage} />
  <Box component={"img"} src={backgroundImage} sx={styles.bannerImage(0)} />
  <Box component={"img"} src={gradientImage} sx={styles.bannerImage(1)} />
  <Box sx={styles.logoBox}>
    <Box component={"img"} src={logo} />
    <TYpography sx={styles.logoText}>{configJSON.batteryConceptsInternational}</TYpography>
  </Box>
</Box>
}
// Customizable Area End

import EmaildeliverabilityController, {
  Props,
  configJSON,
} from "./EmaildeliverabilityController";

export default class Emaildeliverability extends EmaildeliverabilityController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {(() => {
          const { statusValue, isQuoteSentAlready } = this.state
          switch (this.state.mainStep) {
            case 0:
              return <>
                <NavigationMenuBar navigation={null} id="" />
                <Box sx={styles.main}>
                  <Box sx={styles.mainBox}>
                    <Box sx={styles.logosBox}>
                      <Box sx={styles.logo} component={"img"} src={logo} />
                      <Box sx={styles.hawker} component={"img"} src={hawker} />
                    </Box>
                    <Box sx={styles.tableBox}>
                      <TYpography data-test-id="heading"  sx={styles.quoteHeading}>{configJSON.quoteHeading}</TYpography>
                      <Box sx={styles.quoteStateBox(true)}>
                        <TYpography sx={styles.quoteStateText}>{configJSON.quoteStateText}</TYpography>
                        <BUtton data-test-id="status-change-button" variant="contained"
                          endIcon={<KeyboardArrowRight />}
                          onClick={this.handleStatusChange}
                          sx={styles.sentButton(this.colorCodes[this.statusChanges.indexOf(statusValue)])}>{statusValue}</BUtton>
                      </Box>
                      <Box sx={styles.quoteStateBox(false)}>
                        <Box >
                          <TYpography sx={styles.dateText}>{configJSON.dateText}</TYpography>
                          <TYpography sx={styles.content}>{configJSON.date}</TYpography>
                        </Box>
                        <Box>
                          <TYpography sx={styles.dateText}>{configJSON.quoteHeading}#</TYpography>
                          <TYpography sx={styles.content}>{configJSON.quote}</TYpography>
                        </Box>
                      </Box>
                      <Box sx={styles.quoteStateBox(false)}>
                        <Box sx={styles.dateBox}>
                          <TYpography sx={styles.dateText}>{configJSON.billToText}</TYpography>
                          <TYpography sx={styles.content}>{this.rows[0].fat}</TYpography>
                        </Box>
                      </Box>
                      <Box sx={styles.quoteStateBox(false)}>
                        <Box sx={styles.dateBox}>
                          <TYpography sx={styles.dateText}>{configJSON.shipTo}</TYpography>
                          <TYpography sx={styles.content}>{this.rows[0].fat}</TYpography>
                        </Box>
                      </Box>
                      <Box sx={styles.quoteStateBox(false)}>
                        <Box sx={styles.halfBox(true)}>
                          <TYpography sx={styles.dateText}>{configJSON.termsText}</TYpography>
                          <TYpography sx={styles.content}>{configJSON.net}</TYpography>
                        </Box>
                        <Box sx={styles.halfBox(false)}>
                          <TYpography sx={styles.dateText}>{configJSON.salesPerson}</TYpography>
                          <TYpography sx={styles.content}>{configJSON.net}</TYpography>
                        </Box>
                      </Box>
                      <Box sx={styles.quoteStateBox(false)}>
                        <TYpography sx={styles.totalQuote("14px")}>{configJSON.totalQuote}</TYpography>
                        <TYpography sx={styles.totalQuote("24px")}>$ {configJSON.quoteRupees}</TYpography>
                      </Box>
                      <Box sx={styles.downloadBox}>
                        <BUtton variant="outlined" endIcon={<Box component={"img"} src={download} />}
                          sx={styles.downloadButton}>{configJSON.downloadPdfText}</BUtton>
                      </Box>
                      <BUtton
                        data-test-id="send-quote-button"
                        variant="contained"
                        onClick={() => this.handleMassageNavigate(!isQuoteSentAlready ? "sendTheQuote" : "reSendTheQuote")}
                        endIcon={<Box component={"img"} src={send} />}
                        sx={styles.sendButton}>
                        {!isQuoteSentAlready ? configJSON.sendQuoteText : configJSON.yesResendQuote}
                      </BUtton>
                      <BUtton
                        data-test-id="edit-quote-button"
                        onClick={() => this.handleMassageNavigate("editTheQuote")}
                        variant="outlined"
                        endIcon={<Box component={"img"} src={edit} />}
                        sx={styles.editButton}>{configJSON.editQuote}</BUtton>
                    </Box>
                    <TYpography sx={styles.quoteTableHeading}>{configJSON.quoteDetailsText}</TYpography>
                    <TableContainer >
                      <Table sx={styles.table} aria-label="simple table">
                        <TableHead>
                          <TableRow sx={styles.mainTableRow}>
                            {this.quoteTableHeadings.map((heading) => (
                              <TableCell key={heading} align="center">{heading}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.rows.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={styles.bodyTableRow}
                            >
                              <TableCell component="th" scope="row" sx={styles.tableCell("76px")}>
                                {row.name}
                              </TableCell>
                              <TableCell sx={styles.tableCell("84px")} align="center">{row.calories}</TableCell>
                              <TableCell sx={styles.tableCell("398px")}>{row.fat}</TableCell>
                              <TableCell sx={styles.tableCell("120px")} align="center">{row.carbs}</TableCell>
                              <TableCell sx={styles.tableCell("120px")} align="center">{row.protein}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow
                            sx={styles.subTotalRow}
                          >
                            <TableCell component="th" scope="row" sx={styles.tableCell("76px")}></TableCell>
                            <TableCell sx={styles.tableCell("84px")} align="center"></TableCell>
                            <TableCell sx={styles.tableCell("398px")}>{configJSON.subTotal}</TableCell>
                            <TableCell sx={styles.tableCell("120px")} align="center"></TableCell>
                            <TableCell sx={styles.tableCell("120px")} align="center">{configJSON.quoteRupees}</TableCell>
                          </TableRow>
                          {this.rows.map((quote) => (
                            <TableRow
                              key={quote.name}
                              sx={styles.bodyTableRow}
                            >
                              <TableCell component="th" scope="row" sx={styles.tableCell("76px")}>
                                {quote.name}
                              </TableCell>
                              <TableCell sx={styles.tableCell("84px")} align="center">{quote.calories}</TableCell>
                              <TableCell sx={styles.tableCell("398px")}>{quote.fat}</TableCell>
                              <TableCell sx={styles.tableCell("120px")} align="center">{quote.carbs}</TableCell>
                              <TableCell sx={styles.tableCell("120px")} align="center">{quote.protein}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              </>
            case 1:
              return <>
                {bannerComponent()}
                <Box sx={styles.tableBox}>
                  <TYpography sx={styles.changeStatusText} data-test-id="heading">{configJSON.changeStatus}</TYpography>
                  <RadioGroup
                    value={statusValue}
                    onChange={this.handleStatus}
                    data-test-id="radio-group"
                  >
                    {this.statusChanges.map((status, index) => (
                      <FormControlLabel
                        key={status}
                        value={status}
                        control={<Radio />}
                        sx={styles.radioButton(status === statusValue, index == 0)}
                        label={status}
                      />
                    ))}
                  </RadioGroup>
                  <BUtton
                    data-test-id="change-status-button"
                    variant="contained"
                    onClick={() => this.handleMassageNavigate("changeTheStatus")}
                    sx={styles.sendButton}>
                    {this.messageObj[this.state.messageStep].buttonText}
                  </BUtton>
                </Box>
              </>
            case 2:
              return <>
                <Box sx={styles.main}>
                  {bannerComponent()}
                  <Box sx={styles.messageBox}>
                    <TYpography data-test-id="success-heading" sx={styles.updateProfileText}>
                      {this.messageObj[this.state.messageStep].messageHeading}
                    </TYpography>
                    <TYpography sx={styles.updateProfileBelowText}>
                      {this.messageObj[this.state.messageStep].messageHeading}
                    </TYpography>
                    <BUtton
                      variant="contained"
                      data-test-id="message-main-button"
                      onClick={this.handleMessageButton}
                      sx={styles.sendButton}>
                      {this.messageObj[this.state.messageStep].buttonText}
                    </BUtton>
                    {
                      this.goBackButtonCondition() && <BUtton data-test-id='go-back-button' onClick={this.handleGoBack} variant="outlined"
                        sx={styles.editButton}>{configJSON.noGoBack}</BUtton>
                    }
                  </Box>
                </Box>
              </>
          }
        })()}
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  radioButton: (isCurrent: boolean,isFirstBox:boolean) => ({
    borderTop: `${isFirstBox ? "1px" : "0px"} solid #D6D3D1`,
    borderBottom: "1px solid #D6D3D1",
    py: "3%",
    "& .MuiTypography-root": {
      fontWeight: isCurrent ? 700 : 400,
      fontSize: "16px",
      color: "#78716C"
    },
    "& .MuiButtonBase-root.MuiRadio-root.Mui-checked": {
      color: "#C7161D"
    },
    "& .MuiButtonBase-root.MuiRadio-root": {
      color: "#A8A29E",
      height: "16px",
      width: "16px",
      bgcolor: "#E7E5E4",
      borderWidth: "1px",
      mr: "3%",
      ml: "4%"
    }
  }),
  changeStatusText:{
    fontSize:"32px",
    fontWeight:700,
    color:"#171717",
    mt:"8%",
    mb:"6%",
    "& + .MuiFormGroup-root":{
      mb:"5%"
    }
  },
  messageBox:{ 
    width: "343px",
    mt:"8%",
    mx:"auto"
  },
  updateProfileText: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#171717",
  },
  updateProfileBelowText: {
    my: 3,
    fontWeight: 700,
    color: "#78716C",
  },
  bannerImage: (zIndexNumber: number) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: zIndexNumber,
    borderRadius: "25px",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  }),
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: 'translate(-50%, -150%)',
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none",
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
  iconButton: {
    position: "absolute",
    color: "white",
    left: 10,
    top: 10,
    zIndex: 3,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  icon: {
    fontSize: 30,
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block",
      borderRadius: "25px 25px 0px 0px",
      overflow: "hidden",
    },
  },
  imageBox: {
    position: "relative",
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoBox: {
    position: "absolute",
    zIndex: 2,
     display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  logoText: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "18px",
    textAlign: "center",
   },
  totalQuote: (fontSize: string) => ({
    fontWeight: 700,
    fontSize: fontSize,
    color: '#455992'
  }),
  table: {
    minWidth: 900,
    mb: "10%"
  },
  mainTableRow: {
    bgcolor: "#F3F3F3",
    '& td, & th': {
      border: "1px solid #D6D3D1",
      fontSize: "12px",
      fontWeight: 700,
      color: "#171717"
    }
  },
  bodyTableRow: {
    '& td, & th': {
      border: "1px solid #D6D3D1",
      fontSize: "12px",
      fontWeight: 400,
      color: "#171717",
      width: "230px"
    }
  },
  subTotalRow: {
    '& td, & th': {
      border: "1px solid #D6D3D1",
      fontSize: "16px",
      fontWeight: 700,
      color: "#171717"
    }
  },
  tableCell: (width: string) => ({
    width: width
  }),
  quoteTableHeading: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#000000",
    mb: "2%",
    mt: "5%"
  },
  sendButton: {
    my: "5%",
    "&.MuiButtonBase-root.MuiButton-root": {
      bgcolor: "#C7161D",
      width: "100%",
      height: "56px",
      borderRadius: "8px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF"
    }
  },
  editButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      border: "1px solid #171717",
      height: "56px",
      width: "100%",
      borderRadius: "8px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      color: "#171717"
    }
  },
  downloadBox: {
    display: "flex",
    justifyContent: "center",
    mt: "4%"
  },
  downloadButton: {
    "&.MuiButtonBase-root.MuiButton-root": {
      border: "1px solid #171717",
      height: "34px",
      width: "173px",
      borderRadius: "8px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "14px",
      color: "#171717"
    }
  },
  halfBox: (isFirstBox: boolean) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    borderRight: `${isFirstBox ? "1px" : "0px"} solid #D6D3D1`
  }),
  content: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#171717"
  },
  quoteHeading: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#000000",
    my: "5%"
  },
  dateBox: {
    width: "50%"
  },
  sentButton:(bgColor:string) =>({
    "&.MuiButtonBase-root.MuiButton-root": {
      bgcolor:bgColor,
      width: "104px",
      height: "34px",
      borderRadius: "4px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "14px",
      color: "#FFFFFF"
    }
  }),
  quoteStateText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#171717"
  },
  dateText: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#171717",
    pb: "1%"
  },
  main: {
    height: "100vh",
    overflow: "auto"
  },
  mainBox: {
    mx: "auto",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      width: "900px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px"
    }
  },
  tableBox: {
    mx: "auto",
    [theme.breakpoints.up("md")]: {
      width: "600px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px"
    }
  },
  quoteStateBox: (isFirstBox: boolean) => ({
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `${isFirstBox ? "1px" : "0px"} solid #D6D3D1`,
    borderBottom: "1px solid #D6D3D1",
    py: "4%"
  }),
  logosBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mx: "auto",
    mt: "3%",
    [theme.breakpoints.up("md")]: {
      width: "480.21px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px"
    }
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      width: "143.21px",
      height: "64px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "143.21px",
      height: "64px"
    }
  },
  hawker: {
    [theme.breakpoints.up("md")]: {
      width: "224px",
      height: "64px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "143.21px",
      height: "64px"
    }
  }
}
// Customizable Area End
