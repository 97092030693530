import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {
  ChangeEvent,
  createRef,
  RefObject,
  KeyboardEvent
} from "react";
interface Validations {
  [key:string]:(value:string)=>string
}
interface Input{
  key:string,
  name: string;
  value: string;
  type:string,
  placeHolder: string;
  errorMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  fullName: string;
  employment: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showPassword: boolean
  error: boolean
  isCheckedTermsPrivacy: boolean
  displayAlert:{message:string,isSuccess:boolean},
  inputs:Input[],
  profilePictureFile:null|File;
  stepValue:number,
  otp:string[],
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelFullName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  buttonText=[
    configJSON.signUp,
    configJSON.enterCode,
    configJSON.verify,
    configJSON.goToLogin
  ]
  otpInputs: RefObject<HTMLInputElement>[];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      fullName: "",
      employment: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showPassword: false,
      error: false,
      isCheckedTermsPrivacy: false,
      displayAlert:{message:"",isSuccess:false},
      inputs:[
        {key:"fullName",name:"Full Name",value:"",type:"text",placeHolder:"Your Full Name",errorMessage:""},
        {key:"phone",name:"Phone",value:"",type:"text",placeHolder:"Your Phone",errorMessage:""},
        {key:"workEmail",name:"Work Email",value:"",type:"email",placeHolder:"Your Work Email",errorMessage:""},
        {key:"pwd",name:"Password",value:"",type:"password",placeHolder:"Your password",errorMessage:""},
        {key:"placeOfEmployment",name:"Place of Employement",type:"text",value:"",placeHolder:"Place of employement",errorMessage:""},
      ],
      profilePictureFile:null,
      stepValue:1,
      otp: ["", "", "", ""],
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelFullName = configJSON.labelFullName; 
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.otpInputs = Array(4).fill(null).map(() => createRef<HTMLInputElement>());
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      full_name: this.state.fullName,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone,
      email: this.state.email,
      password: this.state.password,
      employment: this.state.employment,
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };


  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  handleClickTermsAndPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ isCheckedTermsPrivacy: event.target.checked });
  }
  handleNavigate = (route: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  handleRemoveError = () => {
    this.setState({ displayAlert: { message: "", isSuccess: false } })
  }
  conditionFunc = (condition: boolean, trueValue: string, falseValue: string) => {
    return condition ? trueValue : falseValue
  }
  conditionIcon = (condition: boolean, firstIcon: React.ReactNode, secondIcon: React.ReactNode) => {
    return condition ? firstIcon : secondIcon
  }
  nameSwitch = (name: string) => {
    switch (true) {
      case name.length === 0:
        return configJSON.requiredField
      case !configJSON.nameRegex.test(name):
        return configJSON.invalidFullName
      default:
        return ""
    }
  }
  phoneSwitch = (phone: string) => {
    switch (true) {
      case phone.length === 0:
        return configJSON.requiredField
      case !configJSON.phoneRegex.test(phone):
        return configJSON.invalidPhone
      default:
        return ""
    }
  }
  emailSwitch = (email: string) => {
    switch (true) {
      case email.length === 0:
        return configJSON.requiredField
      case !configJSON.emailRegex.test(email):
        return configJSON.invalidEmail
      default:
        return ""
    }
  }
  placeSwitch = (place: string) => {
    switch (true) {
      case place.length === 0:
        return configJSON.requiredField
      case !configJSON.placeRegex.test(place):
        return configJSON.invalidPlace
      default:
        return ""
    }
  }
  pwdSwitch = (pwdValue: string) => {
    switch (true) {
      case pwdValue.length === 0:
        return configJSON.requiredField
      case configJSON.pwdRegExp.test(pwdValue):
        return ""
      default:
        return ""
    }
  }
  validations: Validations = {
    fullName: this.nameSwitch,
    phone: this.phoneSwitch,
    workEmail: this.emailSwitch,
    placeOfEmployment: this.placeSwitch,
    pwd: this.pwdSwitch
  } 
  passwordInstructions = [
    { regex: configJSON.atLeastOneCapitalRegExp, text: configJSON.atLeastOneCapital },
    { regex: configJSON.atLeastOneSmallRegExp, text: configJSON.atLeastOneSmall },
    { regex: configJSON.atLeastOneNumberRegExp, text: configJSON.atLeastOneNumber },
  ];
  handlePasswordEye = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }
  handleOnChange = (value: string, key: string, index: number) => {
    const newInputs = [...this.state.inputs]
    newInputs[index] = { ...newInputs[index], value: value, errorMessage: this.validations[key](value) };
    this.setState({ inputs: newInputs })
  }
  handleOnBlur = (key: string, index: number) => {
    const newInputs = [...this.state.inputs]
    newInputs[index] = { ...newInputs[index], errorMessage: this.validations[key](newInputs[index].value) };
    this.setState({ inputs: newInputs })
  }
  handleOnKeyDown = (event: React.KeyboardEvent) => {
    ["-", "+", "=", "ArrowUp"].includes(event.key) && event.preventDefault()
  }
  handleProfilePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ profilePictureFile: event.target.files![0] })
  }
  handleRemoveUploadedImage = () => {
    this.setState({ profilePictureFile: null })
  }
  handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    const {stepValue, inputs, isCheckedTermsPrivacy,otp } = this.state
    switch (stepValue) {
      case 1:
        const findErrors = inputs.map((input) => ({ ...input, errorMessage: this.validations[input.key](input.value) }))
        if (findErrors.some((input) => input.errorMessage !== "")) {
          this.setState({ inputs: findErrors })
        }
        if (!isCheckedTermsPrivacy) {
          this.setState({ displayAlert: { message: configJSON.youHaveToAgreeText, isSuccess: false } })
        }
        if (findErrors.every((input) => input.errorMessage === "" && isCheckedTermsPrivacy)) {
          this.setState({ stepValue: 2,displayAlert:{message: "",isSuccess:false } })
        }
        break
      case 2:
        this.setState({ stepValue: 3,displayAlert:{message: "",isSuccess:false } })
        break
      case 3:
        if (otp.every((value:string)=>value!=="")) {
          this.setState({ displayAlert:{message: "",isSuccess:false },stepValue:4})
          } else{
            this.setState({ displayAlert:{message: configJSON.wrongCodePleaseTryAgainText,isSuccess:false }})
          }
        break
      default: {
        this.handleNavigate("Cfquotebuilder")
      }
    }
  }
  handleOtpOnChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;
    if (isNaN(Number(value))) return; 
    let otp = [...this.state.otp];
    otp[index] = value; 
    this.setState({ otp }, () => {
       if (value && index < this.otpInputs.length - 1) {
         this.otpInputs[index + 1].current?.focus();
      }
    });
  };
  handleOtpKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
    if(event.key===" "){
      event.preventDefault()
    }
     if (event.key === "Backspace" && !this.state.otp[index] && index > 0) {
       this.otpInputs[index - 1].current?.focus();
    }
  };
  handleResentTheCode=()=>{
    this.setState({displayAlert:{message:configJSON.theCodeHasBeenResent,isSuccess:true}})
  }
  handleCheckButton=()=>{
    if(this.state.stepValue===1){
      const findErrors = this.state.inputs.map((input) => ({ ...input, errorMessage: this.validations[input.key](input.value)}))
      return findErrors.every((input) => input.errorMessage === "")
    }else{
      return true
    }
  }
  // Customizable Area End
}
