Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "emaildeliverability";
exports.labelBodyText = "emaildeliverability Body";

exports.btnExampleTitle = "CLICK ME";
exports.quoteHeading="QUOTE";
exports.quoteStateText="Quote State"
exports.sentText="Sent";
exports.dateText="Date";
exports.date="10/09/2024";
exports.billToText="BILL TO";
exports.shipTo="SHIP TO";
exports.termsText="TERMS";
exports.salesPerson="SALES PERSON";
exports.downloadPdfText="Download PDF"
exports.sendQuoteText="Send Quote";
exports.editQuote="Edit Quote";
exports.quoteDetailsText="QUOTE DETAILS";
exports.quote="0000070924";
exports.net="NET 30";
exports.subTotal='SUBTOTAL';
exports.totalQuote="TOTAL QUOTE";
exports.quoteRupees="98,88,998";
exports.batteryConceptsInternational="Battery Concepts International";
exports.sendTheQuote="Are you sure you want to send the quote?";
exports.sendTheQuoteBelowText="Please review all details before submitting.";
exports.sendTheQuoteSuccess="Quote Submitted Successfully!";
exports.sendTheQuoteSuccessBelowText="Your quote has been successfully submitted.";
exports.reSendTheQuote="Are you sure you want to resend the quote?";
exports.reSendQuoteBelowText="A new quote will be sent to your email. Please confirm.";
exports.reSendTheQuoteSuccess="Quote Resent Successfully!";
exports.reSendTheQuoteSuccessBelowText="The quote has been resent to your email. Please check your inbox for the updated information.";
exports.editTheQuote ="Are you sure you want to edit the quote?";
exports.editTheQuoteBelowText="Please take into account that this process will create a new quote ";
exports.changeTheStatus="Are you sure you want to change the status of this quote?";
exports.changeTheStatusBelowText="Review your choice carefully before proceeding."
exports.yesSendQuote="Yes, Send Quote";
exports.continue="Continue";
exports.yesResendQuote="Yes, Resend Quote";
exports.yesEditQuote="Yes, Edit Quote";
exports.yesChangeStatus="Yes, Change Status"
exports.noGoBack="No, Go Back";
exports.changeStatus="Change Status"
// Customizable Area End