Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.headingTxtSignIn="Sign In";
exports.placeHolderYourEmail="Your email";
exports.placeHolderYourPassword="Your password"
exports.forgotYourPasswordText="Forgot your password?";
exports.emailRegExp=/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.pwdRegExp=/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.incorrectEmailOrPassword="Incorrect email or password";
exports.donNotHaveAnAccountYet="Don’t have an account yet?";
exports.createOne="Create One";
exports.loginEndPoint="bx_block_login/api/v1/login"
// Customizable Area End