import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface StaticCheckBoxData {
    name: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any;
    initialValues: {
        Answer: string;
        months: string[];
    };
    staticCheckBoxData: StaticCheckBoxData[];
    selectedValue: boolean;
    selectedValue2: boolean;
    showInnerBox: boolean;
    activeBox: number | null
    profilePictureFile: null | File;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AccesoriesController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetDataCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            initialValues: {
                Answer: "",
                months: [],
            },
            staticCheckBoxData: [
                {
                    name: "Battery Boss",
                },
                {
                    name: "Spider Watering System (High Pressure)",
                },

                {
                    name: "Stealth Watering System (Low Pressure)",
                },

                {
                    name: "Blink Light",
                },

                {
                    name: "A Frame Handle (On Battery Conector)",
                },

                {
                    name: "Euro Connector",
                },

                {
                    name: "Jumper Cables",
                },

                {
                    name: "SBX Connector",
                },

                {
                    name: "Charger Stands",
                },

                {
                    name: "POGO Sticks",
                },

            ],
            selectedValue: false,
            selectedValue2: false,
            showInnerBox: false,
            activeBox: null,
            profilePictureFile: null



            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    isChecked = (value: string, months: string[]) => {
        return months.includes(value);
    };

    handleCheckboxChange = (value: string, setFieldValue: (field: string, value: any) => void, months: string[]) => {
        const newMonths = months.includes(value)
            ? months.filter(month => month !== value)
            : [...months, value];
        setFieldValue('months', newMonths);
    };
    conditionFunc = (condition: boolean, trueValue: string, falseValue: string) => {
        return condition ? trueValue : falseValue
    }
    conditionIcon = (condition: boolean, firstIcon: React.ReactNode, secondIcon: React.ReactNode) => {
        return condition ? firstIcon : secondIcon
      }

    handleProfilePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ profilePictureFile: event.target.files![0] })
    }
    handleRemoveUploadedImage = () => {
        this.setState({ profilePictureFile: null })
    }

    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area End
}
