import React from "react";

// Customizable Area Start
import {StyleSheet } from "react-native";
import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  IconButton
} from "@mui/material";
import {
  ArrowBack,
  CheckCircleOutlineRounded,
  HighlightOff,
  VisibilityOutlined,
  VisibilityOffOutlined
} from '@mui/icons-material';
import {belowBannerImage,aboveBannerImage,mobileBelowImage,logo } from "./assets";
import  {configJSON} from "./EmailAccountLoginController";
const theme = createTheme({
  typography:{
    fontFamily:"Quicksand"
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#E7E5E4",
            color: "#171717",
            borderRadius: 10,
            fontSize: 16,
            fontWeight: 500,
            height: "56px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          "& input:-webkit-autofill": {
            transition:
              "background-color 0s 600000s, color 0s 600000s !important",
          },
          "& input": {
            paddingBlock: 16,
            paddingInline: 18,
            color: "#171717"
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-focused)":
          {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderWidth: 2,
            },
          },
        },
      },
    },
  }
})
const webStyles = {
  mainBox: {
    display: "flex",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
  },
  bannerMainBox: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "45%",
    },
    [theme.breakpoints.up("xs")]: {
      height: "170px",
      width: "100%",
    },
  },
  belowBannerImage: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "25px",
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      borderRadius: "25px 25px 0px 0px",
      display: "none",
    },
  },
  aboveBannerImage: {
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "25px",
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      borderRadius: "25px 25px 0px 0px",
      display: "none",
    },
  },
  contentMainBox: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "55%",
      alignItems: "center",
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      width: "100%",
      alignItems: "start",
    },
  },
  contentInnerBox: {
    [theme.breakpoints.up("md")]: { width: 360 },
    [theme.breakpoints.up("sm")]: { width: 350 },
    [theme.breakpoints.up("xs")]: { width: 320 },
  },
  errorText: (isError: boolean) => ({
    visibility: isError ? "visible" : "hidden",
    fontWeight: 700,
    fontSize: "12px",
    textAlign: "center",
    color:  "#DC2626",
    bgcolor: "#F5F5F4",
    width: "100%",
    height: "34px",
    p: "16px 8px",
    my: 2.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "md")]: {
      mt: "5%",
    },
    "& .MuiSvgIcon-root": {
      color:  "DC2626",
    },
  }),
  blockHeading: {
    fontWeight: 700,
    fontSize: 32,
    color: "#171717",
    mb: "10%",
  },
  headingBelowText: {
    color: "#78716C",
    fontWeight: 400,
    fontSize: "14px",
  },
  label: {
    color: "#78716C",
    fontWeight: 700,
    mt: "5%",
  },
  passwordLabel: {
    color: "#78716C",
    fontWeight: 700,
    mt: "5%",
  },
  navigateTextBox: () => ({
    display: "flex",
    justifyContent: "end",
    mt: "5%",
  }),
  navigateText: {
    fontWeight: 700,
    pb: 0.5,
    fontSize: 12,
  },
  button: (isExist: boolean) => ({
    "&.MuiButton-root": {
      bgcolor: isExist ? "#C7161D" : "#A8A29E",
      color: !isExist ? "#D6D3D1" : "#FFFFFF",
      width: "100%",
      p: 2,
      mt: "8%",
      borderRadius: "8px",
      textTransform: "capitalize",
      fontSize: 18,
      fontWeight: 700,
    },
  }),
  createAccountBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 1,
    mt: "3%",
  },
  doNotHaveText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#171717",
  },
  createOneText: {
    fontWeight: 700,
    fontSize: "12px",
    color: "#C7161D",
  },
  iconButton: {
    position: "absolute",
    color: "white",
    left: 15,
    top: 10,
    zIndex: 3,
    display: "none",
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
  icon: {
    fontSize: 30,
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block",
      borderRadius: "25px 25px 0px 0px",
    },
  },
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: "translate(-50%, 50%)",
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none",
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
};
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={webStyles.mainBox}>
          <Box sx={webStyles.bannerMainBox}>
            <IconButton data-testid="go-back-navigate" sx={webStyles.iconButton} onClick={()=>this.handleNavigate("NavigationMenu")}>
               <ArrowBack sx={webStyles.icon} />
             </IconButton>
             <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={webStyles.mobileBannerImage} />
             <Box component={"img"} src={logo} sx={webStyles.logoImage} />
            <Box component={"img"} src={belowBannerImage} sx={webStyles.belowBannerImage} />
            <Box component={"img"} src={aboveBannerImage} sx={webStyles.aboveBannerImage} />
          </Box>
          <Box sx={webStyles.contentMainBox}>
            <Box sx={webStyles.contentInnerBox} >
              <Typography data-testid="alert-message" sx={webStyles.errorText(Boolean(this.state.displayAlert.message))}>
                <IconButton data-testid="remove-error-button"
                  onClick={this.handleRemoveError}
                >
                  {this.conditionIcon(this.state.displayAlert.isSuccess, <CheckCircleOutlineRounded />, < HighlightOff />)}
                </IconButton>
                {this.state.displayAlert.message}
              </Typography>
              <>
                <Typography sx={webStyles.blockHeading} data-testid="sign-in-heading">
                  {configJSON.headingTxtSignIn}
                </Typography>
                <Box>
                  <Typography sx={webStyles.label}>
                    {configJSON.placeHolderEmail}
                  </Typography>
                  <TextField 
                    autoComplete="off" 
                    data-testid="email-input" 
                    type="email" value={this.state.email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event, "email")} 
                    placeholder={configJSON.placeHolderYourEmail} fullWidth
                  />
                </Box>
                <Box>
                  <Typography sx={webStyles.label}>
                    {configJSON.placeHolderPassword}
                  </Typography>
                  <TextField 
                    data-testid="password-input" 
                    InputProps={{ endAdornment: <IconButton onClick={this.handleClickShowPassword}>
                    {this.conditionIcon(this.state.enablePasswordField , <VisibilityOffOutlined /> , <VisibilityOutlined />)}</IconButton> }} 
                    type={this.conditionFunc(!this.state.enablePasswordField, "text", "password")} 
                    value={this.state.password} 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event, "password")} 
                    placeholder={configJSON.placeHolderYourPassword} 
                    fullWidth />
                  <Box sx={webStyles.navigateTextBox()}>
                    <Typography data-testid="forgot-password-navigate" component={"a"}
                      onClick={()=>this.handleNavigate("ForgotPassword")}
                      sx={webStyles.navigateText}>{configJSON.forgotYourPasswordText}</Typography>
                  </Box>
                </Box>
              </>
              <Button data-testid="submit-button" variant="contained"
                onClick={this.handleOnSubmit}
                sx={webStyles.button(this.handleGetValue())}
              >{configJSON.labelTitle}</Button>
              <Box sx={webStyles.createAccountBox}> 
                <Typography sx={webStyles.doNotHaveText}>{configJSON.donNotHaveAnAccountYet}</Typography>
                <Typography                      
                   data-testid="sign-up-navigate"
                   component={"a"}
                   onClick={()=>this.handleNavigate("EmailAccountRegistration")} sx={webStyles.createOneText}>{configJSON.createOne}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
