// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    privacyPolicyData: string,
    loadingStatus:"INITIAL"|"PENDING"|"SUCCEEDED"|"FAILED";
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class TnCandPrivacyPolicyController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    privacyPolicyCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];
    
        this.state = {
            // Customizable Area Start
             privacyPolicyData: "",
            loadingStatus:"INITIAL"
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      }

      // Customizable Area Start
      async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId != null) {
               if(apiRequestCallId === this.privacyPolicyCallId)this.handlePrivacyPolicyResponseJSON(message)
            }
        }
        // Customizable Area End
    };

    async componentDidMount(){
        this.handleGetPrivacyPolicy()
    };

    handleGetPrivacyPolicy = async () => {
        this.setState({loadingStatus:"PENDING"})
        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.privacyPolicyCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllPrivacyPolicy
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

    };

    handlePrivacyPolicyResponseJSON = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.description) {
            this.setState({
                privacyPolicyData: responseJson.description,
                loadingStatus: "SUCCEEDED"
            })
        } else if (responseJson.error) {
            this.setState({
                privacyPolicyData: responseJson.error,
                loadingStatus: "FAILED"
            })
        } else {
            this.setState({
                privacyPolicyData: configJSON.somethingWentWrong,
                loadingStatus: "FAILED"
            })
        }
    }
    handleNavigateToSignUp = () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(getName(MessageEnum.NavigationTargetMessage),'EmailAccountRegistration');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        this.send(message);
    }
      // Customizable Area End
}

// Customizable Area Start
// Customizable Area End