// Customizable Area Start

import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqController, { Props } from "./FaqController.web";
import { aboveBannerImage, bciImage, mobileBelowImage } from "./assets";
import NavigationMenuBar from "../../../../packages/components/src/NavBar.web";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default class Faq extends FaqController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <>

                <NavigationMenuBar navigateToAnyPage={this.navigateToAnyPage} navigation={this.props.navigation} id="" />
                <ImageHeaderBox>
                    <img src={mobileBelowImage} style={{ width: "100%", height: "100%" }} />
                    <img src={aboveBannerImage} style={{ width: "100%", height: "100%" }} />
                    <Box
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center"
                        }}
                    />
                    <BackArrowIconBox>
                        <ArrowBackIcon />
                    </BackArrowIconBox>
                    <Logo>
                        <img src={bciImage} style={{ width: "100%" }} />
                    </Logo>
                </ImageHeaderBox>

                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <MainBox>
                        <Box sx={webStyle.headerBox}>
                            <Typography sx={webStyle.headerDesign}>FAQ</Typography>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                            {this.faqData.map((item, index) => (
                                <Box key={index} sx={webStyle.questionBox}>
                                    <Accordion sx={{
                                        backgroundColor: "#F5F5F4",
                                    }}>
                                        <AccordionSummary sx={{ height: "65px" }}
                                            expandIcon={<ExpandMoreIcon sx={{ cursor: "pointer", color: "#C7161D" }} />}
                                        >
                                            <Typography sx={webStyle.headerTypo}>
                                                {item.question}
                                            </Typography>
                                        </AccordionSummary>
                                        <hr style={{ width: "90%", color: "#C7161D" }} />
                                        <AccordionDetails sx={webStyle.accordionDetails}>

                                            <Typography sx={webStyle.answerTypo}>
                                                {item.answer}
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                            ))}
                        </Box>
                    </MainBox >
                </Box >
            </>
        );
    }
}

const webStyle = {
    headerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    accordionBox: {
        backgroundColor: "#F5F5F4",
    },
    accordionDetails: {
        marginTop: "8px",
        paddingTop: "8px",
    },
    headerDesign: {
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        textAlign: "left",
    },
    headerTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        marginBottom: "2px"
    },
    questionBox: {
        width: "343px",
    },
    answerTypo: {
        fontFamily: "Quicksand",
        fontWeight: 400,
        lineHeight: '24px',
    }
};

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "343px",
    gap: "32px",
    padding: "0.8rem",
});

const ImageHeaderBox = styled(Box)({
    width: "100%",
    borderRadius: "0px 0px 0px 32px",
    opacity: "1",
    position: "relative",
    overflow: "hidden",
    display: "none",
    "@media (max-width:960px)": {
        display: "block",
        width: "100%",
        borderRadius: "0px 0px 0px 32px",
        opacity: "1",
        position: "relative",
        overflow: "hidden",
        height: "160px"
    },
});

const BackArrowIconBox = styled(Box)({
    position: "absolute",
    top: "18%",
    left: "2%",
    color: "white",
    cursor: "pointer",
    zIndex: '1000',
});

const Logo = styled(Box)({
    position: "absolute",
    left: "50%",
    top: "20%",
    transform: "translate(-50%,-50%)",
    width: "15%"
});

// Customizable Area End