import React from "react";

// Customizable Area Start
import {
  Typography,
  Box, 
  createTheme, 
  TextField, 
  CssBaseline,
  ThemeProvider, 
  Avatar, 
  Button, 
  Tabs, 
  Tab, 
  IconButton,
  Modal
} from "@mui/material";
import { 
  ArrowBack, 
  KeyboardArrowRight,
  CheckCircleOutlineRounded,
  HighlightOff
} from '@mui/icons-material';
import { 
  backgroundImage, 
  gradientImage, 
  logo, 
  upload ,
  mobileBelowImage
} from "./assets"
import Settings2 from "../../settings2/src/Settings2";
import ChangePasswordSettings from "../../settings2/src/ChangePasswordSettings.web";

const theme = createTheme({
  typography: {
    fontFamily: "Quicksand"
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#E7E5E4",
            color: "#171717",
            borderRadius: 10,
            fontSize: 16,
            fontWeight: 500,
            height: "56px",
          },
          '& input[type=number]': {
            '-moz-appearance': 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          }
          ,
          "& input:-webkit-autofill": {
            transition:
              "background-color 0s 600000s, color 0s 600000s !important",
          },
          "& input": {
            paddingBlock: 16,
            paddingInline: 18,
            color: "#171717"
          },
        },
      },
    },
  }
})
// Customizable Area End

import UserProfileBasicController, {
  Props,
  configJSON
} from "./UserProfileBasicController";

export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      inputs,
      tabValue,
      isMobile,
      profilePicture,
      modalType,
      changeEmail,
      isCurrentEmail,
      changeEmailTab,
      otp,
      displayAlert
    }=this.state
    const handleBodyJSX = () => {
      switch (tabValue) {
        case "Profile":
          return (
            <Box data-testid="profile-form" component={"form"} onSubmit={this.handleOnSubmit} sx={styles.contentBox}>
              <Typography sx={styles.heading}>{configJSON.profile}</Typography>
              <Box sx={styles.avatarBox}>
                <Avatar data-testid="profile-avatar" sx={styles.avatar} src={profilePicture ?? ""} />
                <Typography sx={styles.avatarName}>{configJSON.labelFirstName}</Typography>
                <Typography sx={styles.avatarEmail}>{configJSON.errorEmailNotValid}</Typography>
              </Box>
              {inputs.map((input, index) => (
                <Box sx={styles.inputBox} key={input.key}>
                  <Box sx={styles.inputLabelBox}>
                    <Typography sx={styles.inputName}>{input.name}</Typography>
                    <Typography data-testid={`${input.key}-error-text`} sx={styles.errorMessage}>{input.errorMessage}</Typography>
                  </Box>
                  <TextField
                    data-testid={input.key}
                    autoComplete="off"
                    sx={styles.input(Boolean(input.errorMessage))}
                    error={Boolean(input.errorMessage)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event.target.value, input.key, index)}
                    onBlur={() => this.handleOnBlur(input.key, index)}
                    onKeyDown={this.handleOnKeyDown}
                    fullWidth
                    type={input.type}
                    name={input.name}
                    value={input.value}
                    placeholder={input.placeHolder}
                  />
                </Box>
              ))
              }
              <Box sx={styles.inputBox}>
                <Box sx={styles.inputLabelBox}>
                  <Typography sx={styles.inputName}>{configJSON.profilePictureOptional}</Typography>
                </Box>
                <Box sx={styles.uploadBox}>
                  <TextField 
                     onChange={this.handleProfilePicture} 
                     id="uploadImage" 
                     sx={styles.fileInput} 
                     fullWidth 
                     type="file" 
                     data-testid="profile-picture"
                  />
                  <IconButton component={"label"} htmlFor="uploadImage">
                    <Box  component={"img"} src={upload} sx={styles.uploadImage} />
                  </IconButton>
                  <Typography sx={styles.uploadText}>{configJSON.uploadImage}</Typography>
                </Box>
              </Box>
              <Button type="submit" sx={styles.button}>{configJSON.saveChanges}</Button>
            </Box>
           )
        case "Change Email":
          return (
            <Box sx={styles.contentBox}>
              <Box data-testid="alert-message" sx={styles.errorText(Boolean(displayAlert.message), displayAlert.isSuccess)}>
                <IconButton data-testid="remove-error-button" onClick={this.handleRemoveError}>
                  {this.conditionIcon(displayAlert.isSuccess, <CheckCircleOutlineRounded />, < HighlightOff />)}
                </IconButton>
                {displayAlert.message}
              </Box>
              <Typography data-testid="change-email-heading" sx={styles.heading}>{this.handleCondition(changeEmailTab === 1, configJSON.changeEmail, configJSON.enterCode)}</Typography>
              <Typography sx={styles.changeEmailDesc}>{this.handleCondition(changeEmailTab === 1, this.handleCondition(isCurrentEmail, configJSON.changeEmailDescription, configJSON.provideTheNewEmail), configJSON.enterCodeDescription)}</Typography>
              {changeEmailTab === 1 ? <Box sx={styles.inputBox} >
                <Box sx={styles.inputLabelBox}>
                  <Typography sx={styles.inputName}>{this.handleCondition(isCurrentEmail, configJSON.currentEmailLabel, configJSON.newEmail)}</Typography>
                  <Typography data-testid='change-email-error-text' sx={styles.errorMessage}>{changeEmail.errorMessage}</Typography>
                </Box>
                <TextField
                  data-testid="change-email"
                  autoComplete="off"
                  sx={styles.input(Boolean(changeEmail.errorMessage))}
                  error={Boolean(changeEmail.errorMessage)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleChangeEmailOnChange(event.target.value, changeEmail.key)}
                  onBlur={() => this.handleChangeEmailOnBlur(changeEmail.key)}
                  fullWidth
                  type={changeEmail.type}
                  name={changeEmail.name}
                  value={changeEmail.value}
                  placeholder={changeEmail.placeHolder}
                />
              </Box> 
              : <>
                <Box sx={styles.insideInnerMainBox}>
                  <Box sx={styles.otpFieldBox}>
                    {otp.map((value, index: number) => (
                        <TextField
                          data-testid="otp-field"
                          sx={styles.otpField}
                          placeholder={configJSON.hyphen}
                          key={index}
                          type="text"
                          inputProps={{ maxLength: 1, style: { textAlign: "center" } }}
                          value={value}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOtpOnChange(event, index)}
                          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => this.handleOtpKeyDown(event, index)}
                          inputRef={this.otpInputs[index]}
                        />
                      ))
                    }
                  </Box>
                </Box>
                <Box sx={styles.navigateTextBox(true)}>
                  <Typography 
                    data-testid="resend-otp-anchor" 
                    component={"a"} 
                    onClick={this.handleResentTheCode} 
                    sx={styles.navigateText}>{configJSON.resendCode}</Typography>
                </Box>
              </>
              }
              <Button 
                 data-testid="change-email-submit"
                onClick={this.handleChangeEmailOnSubmit} 
                sx={styles.changeEmailButton}>{this.handleCondition(changeEmailTab === 1, this.handleCondition(isCurrentEmail, configJSON.saveChanges, configJSON.continue), configJSON.verify)}</Button>
              {!isCurrentEmail && <Button data-testid="cancel-change-email-button" variant="outlined" onClick={this.handleCancelChangeEmail} sx={styles.outlinedButton}>{configJSON.cancel}</Button>}
            </Box>
            )  
        case "Change Password":
          return <ChangePasswordSettings navigation={this.props.navigation} />
        case "Delete Account":
          return <Settings2 navigation={this.props.navigation} id={this.props.id}/>
      }
    }
     return (
       <ThemeProvider theme={theme}>
         <CssBaseline />
         <Modal
           open={modalType !== "Idle"}
           onClose={this.handleCloseModal}
         >
           <Box sx={styles.modal}>
             <Typography data-testid="success-update-message" sx={styles.updateProfileText}>
               {this.handleCondition(tabValue === "profile", configJSON.yourProfileHasBeenUpdated, configJSON.yourEmailHasBeenUpdated)}
             </Typography>
             <Typography sx={styles.updateProfileBelowText}>
               {this.handleCondition(tabValue === "profile", configJSON.belowUpdateMessageText, configJSON.emailUpdateDescText)}
             </Typography>
             <Button data-testid="close-modal" onClick={this.handleCloseModal} sx={styles.button}>{configJSON.continue}</Button>
           </Box>
         </Modal>
         <Box sx={styles.mainBox}>
           <Box sx={styles.imageBox}>
             <IconButton data-testid="mobile-back-button" sx={styles.iconButton} onClick={this.handleBack}>
               <ArrowBack sx={styles.icon} />
             </IconButton>
             <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={styles.mobileBannerImage} />
             <Box component={"img"} src={logo} sx={styles.logoImage} />
             <Box component={"img"} src={backgroundImage} sx={styles.bannerImage(0)} />
             <Box component={"img"} src={gradientImage} sx={styles.bannerImage(1)} />
             <Box sx={styles.logoBox}>
               <Box component={"img"} src={logo} />
               <Typography sx={styles.logoText}>{configJSON.batteryConceptsInternational}</Typography>
             </Box>
           </Box>
           <Box sx={styles.contentMainBox}>
             <Typography data-testid="settings-heading" sx={styles.settingsHeading(isMobile)}>{configJSON.settings}</Typography>
             <Box sx={styles.mobileTabsBox(isMobile)}>
               <Box sx={styles.avatarBox}>
                <Avatar sx={styles.avatar} alt="avatar image" src={profilePicture??""} />
                 <Typography sx={styles.avatarName}>{configJSON.labelFirstName}</Typography>
                 <Typography sx={styles.avatarEmail}>{configJSON.labelEmail}</Typography>
               </Box>
               {this.tabsData.map((tab, index) => (
                 <Box key={tab} sx={styles.mobileTabBox(index)}>
                   <Typography sx={styles.mobileTab}>{tab}</Typography>
                   <IconButton  data-testid={`tab-${tab}`}  onClick={() => this.handleTabChange(tab)}>
                     <KeyboardArrowRight />
                   </IconButton>
                 </Box>))}
             </Box>
             <Tabs
               value={tabValue}
               data-testid="tabs"
               onChange={(event:React.SyntheticEvent,tabValue)=>this.handleTabChange(tabValue)}
               sx={styles.tabs}
             >
              {this.tabsData.map((tab,index)=>( <Tab key={tab} value={tab} label={tab} />))}
             </Tabs>
             <Box sx={styles.bodyJsxBox(isMobile)}>
               {handleBodyJSX()}
             </Box>
           </Box>
         </Box>
       </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const commonStyles = { 
  center: { 
  display: "flex", 
  flexDirection: "column", 
  justifyContent: "center", 
  alignItems: "center" 
 }
}
const styles = {
  errorText: (isError: boolean, isSuccess: boolean) => ({
    display: isError ? "flex" : "none",
    fontWeight: 700,
    fontSize: "12px",
    textAlign: "center",
    color: isSuccess ? "#059669" : "#DC2626",
    bgcolor: "#F5F5F4",
    width: "343px",
    height:"34px",
    p: "16px 8px",
    my: "2%",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "md")]: { mt: "5%", },
    "& .MuiSvgIcon-root": { color: isSuccess ? "#059669" : "DC2626" }
  }),
  navigateTextBox: (isCenter: boolean) => ({
    display: "flex",
    justifyContent: isCenter ? "center" : "end",
    mt: "2%"
  }),
  navigateText: {
    fontWeight: 700,
    borderBottom: "1px solid",
    mb: "5%",
    fontSize: 12
  },
  insideInnerMainBox: {
    display: "flex",
    justifyContent: "center",
   },
  insideInnerBox: {
    [theme.breakpoints.up("xs")]: {
      width: 300
    }
  },
  otpFieldBox: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    my: "2%",
    height: "70px",
    [theme.breakpoints.up("md")]: {
      width: 340
    },
    [theme.breakpoints.up("sm")]: {
      width: 320
    },
    [theme.breakpoints.up("xs")]: {
      width: 300
    }
  },
  otpField: {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#E7E5E4",
      color: "#171717",
       fontSize: 16,
      fontWeight: 500,
       borderRadius: "5px",
      height: "56px",
      width: "56px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  changeEmailDesc:{ 
    fontWeight: 400,
    fontSize: "14px",
    width: "100%",
    textAlign: "start",
    color: "#171717",
    my: "3%",
    mb:"5%"
  },
  updateProfileText: {
    fontWeight: 700,
    fontSize: "32px",
    color: "#171717",
  },
  updateProfileBelowText: {
    my: 3,
    fontWeight: 700,
    color: "#78716C",
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: "16px",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.up("md")]: {
      width: "439px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px",
    },
  },
  mobileTab: {
    fontWeight: 700,
  },
  bodyJsxBox: (isMobile: boolean) => ({
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      ...commonStyles.center,
    },
    [theme.breakpoints.up("xs")]: {
      display: !isMobile ? "block" : "none",
    },
  }),
  iconButton: {
    position: "absolute",
    color: "white",
    left: 10,
    top: 10,
    zIndex: 3,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  icon: {
    fontSize: 30,
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block",
      borderRadius: "25px 25px 0px 0px",
      overflow: "hidden",
    },
  },
  mobileTabsBox: (isMobile: boolean) => ({
    ...commonStyles.center,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      display: isMobile ? "block" : "none",
    },
  }),
  mobileTabBox: (index: number) => ({
    borderBottom: "1px solid #A8A29E",
    borderTop: `${index == 0 ? "1px" : "0px"} solid #A8A29E`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "70px",
    width: "100%",
    alignItems: "center",
  }),
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: 'translate(-50%, -150%)',
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none",
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
  settingsHeading: (isMobile: boolean) => ({
    fontWeight: 700,
    fontSize: "32px",
    textAlign: "start",
    width: "100%",
    mb: 2,
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: isMobile ? "block" : "none",
    },
  }),
  heading: {
    fontWeight: 700,
    fontSize: "32px",
    textAlign: "start",
    width: "100%",
   },
  bannerImage: (zIndexNumber: number) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: zIndexNumber,
    borderRadius: "25px",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  }),
  inputBox: {
    width: "100%",
    height: "82px",
    mb: "25px",
  },
  mainBox: {
    ...commonStyles.center,
    width: "100%",
  },
  imageBox: {
    position: "relative",
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoBox: {
    width: "342px",
    position: "absolute",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  logoText: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "18px",
    textAlign: "center",
  },
  contentMainBox: {
    ...commonStyles.center,
    width: "800px",
    py: 3,
    [theme.breakpoints.up("md")]: {
      width: "800px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px",
    },
  },
  contentBox: {
    ...commonStyles.center,
    width: "600px",
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px",
    },
  },
  avatarBox: {
    ...commonStyles.center,
    height: "117px",
    mb: 3,
    [theme.breakpoints.between("xs", "md")]: {
      my: 5,
    },
  },
  avatar: {
    width: "80px",
    height: "80px",
  },
  avatarName: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#171717",
    my: "2px",
  },
  avatarEmail: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#A8A29E",
  },
  input: (isError: boolean) => ({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: !isError ? "transparent" : '#C7161D',
      },
      '&:hover fieldset': {
        borderColor: !isError ? "transparent" : '#C7161D',
      },
      '&.Mui-focused fieldset': {
        borderColor: !isError ? "transparent" : '#C7161D',
      },
    },
  }),
  inputName: {
    fontWeight: 700,
    color: "#78716C",
  },
  changeEmailButton: {
    "&.MuiButton-root": {
      mt:"3%",
      borderRadius: "8px",
      height: "55px",
      width: "100%",
      bgcolor: "#C7161D",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF",
    },
  },
  button: {
    "&.MuiButton-root": {
      borderRadius: "8px",
      height: "55px",
      width: "100%",
      bgcolor: "#C7161D",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF",
    },
  },
  outlinedButton: {
    "&.MuiButton-root": {
      mt:"3%",
      borderRadius: "8px",
      height: "55px",
      width: "100%",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      borderColor:"#171717",
      color: "#171717",
    },
  },
  tabs: {
    mb: 3,
    "& .MuiTabs-indicator": {
      bgcolor: "#C7161D",
    },
    "& .MuiButtonBase-root.MuiTab-root": {
      textTransform: "none",
      fontWeight: 400,
      fontSize: "14px",
      color: "#171717",
      width: "192px",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      fontWeight: 700,
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  inputLabelBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    mb: 0.5,
  },
  errorMessage: {
    fontWeight: 700,
    fontSize: "10px",
    color: "#C7161D",
  },
  uploadBox: {
    border: "1px dashed #B4B4B4",
    height: "52px",
    p: "8px",
    borderRadius: "8px",
    gap: "2px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  fileInput: {
    display: "none",
  },
  uploadImage: {
    width: "36px",
    height: "36px",
  },
  uploadText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#78716C",
  },
};
// Customizable Area End
