Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.changePassword = "Change Password";
exports.currentPassword = "Current Password";
exports.newPassword = "New Password";
exports.confirmNewPassword = "Confirm New Password";

exports.capitalLetterVal = "At least one capital letter";
exports.lowercaseLetterVal = "At least one lowercase letter";
exports.numberVal = "At least one number";
exports.minCharVal = "Minimum character length is 8 characters";

exports.changePasswordBtn = "Change Password";
exports.goBackBtn = "No, Go Back";

exports.successHeading = "Success: Your Password has been updated!";
exports.successText = "Now you can Login using your updated Password.";
exports.goToLoginBtn = "Go to Login";
// Customizable Area End