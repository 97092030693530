import React from "react";

// Customizable Area Start
import { Box, Button, Grid, Typography } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { analayticlogo, innerimg1, mobileviewImg, navbarImg, outerimg1, priceimg, qouteSoldLogo, quotePriceLogo, revenueimg, soldimg, totalRevenueLogo } from "./assets";
import { styled } from "@mui/styles";
import Navbar from "../../../../packages/components/src/NavBar.web";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderImage = (title: number) => {
    switch (title) {
      case 0:
        return <Box style={{
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "130px",
        }}>
          <img src={outerimg1.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />
          <img src={innerimg1.default} style={{ position: "absolute", opacity: "0.4" }} />
        </Box>
      case 1:
        return <img src={soldimg.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />;
      case 2:
        return <img src={revenueimg.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />;
      case 3:
        return <img src={priceimg.default} style={{ position: "relative", width: "108px", height: "108px", opacity: "0.4" }} />;
      default:
        break;
    }
  }

  renderlogo = (title: number) => {
    switch (title) {
      case 0:
        return <img src={analayticlogo} alt="analogo" />
      case 1:
        return <img src={qouteSoldLogo} alt="analogo" />
      case 2:
        return <img src={totalRevenueLogo} alt="analogo" />
      case 3:
        return <img src={quotePriceLogo} alt="analogo" />
      default:
        break;
    }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Navbar navigateToAnyPage={this.navigateToAnyPage} navigation={this.props.navigation} id="" />
        <NavigationMenu 
        navigation={null} 
        id=""
        srcURL={mobileviewImg}
        />
        <Box style={{display:"flex",justifyContent:"center"}}>
          <MainBox>
            <Box sx={webStyle.headerBox}>
              <Typography sx={webStyle.headerTypo}>Analytics</Typography>
              <Button
                variant="contained"
                sx={webStyle.buttontypo}

              >
                Filter <FilterAltOutlinedIcon /></Button>
            </Box>
            <Box>
              <Typography sx={webStyle.typo1}>View your total quotes, sold quotes, revenue from sales, and average quote price—all in one convenient place.</Typography>
            </Box>

            <Grid container>

              {Object.keys(this.state.dashBoardData).map((data, index) => (
                <Grid item xs={6} md={6} lg={6} style={{ paddingBottom: "10px" }}>
                  <CardBox>
                    <Box style={{ textAlign: "end" }}>
                      {this.renderlogo(index)}
                    </Box>
                    <Box style={{ marginLeft: "10px" }}>
                      <Typography sx={webStyle.CardNumberTypo}>{this.formatNumber(this.state.dashBoardData[data])}</Typography>
                    </Box>

                    <ImageBox>
                      <TypoBox>
                        <Typography sx={webStyle.CardTextTypo}>{data.replace(/_/g, " ")}</Typography>
                      </TypoBox>
                      <ImgBoxStyle sx={webStyle.imgBox}>
                        {this.renderImage(index)}
                      </ImgBoxStyle>
                    </ImageBox>
                  </CardBox>
                </Grid>
              ))}
            </Grid>
          </MainBox>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerTypo: {
    fontFamily: "Quicksand",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    textalign: "left",

  },
  buttontypo: {
    textTransform: "capitalize",
    fontFamily: "Quicksand",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "24px",
    backgroundColor: "rgba(231, 229, 228, 1) !important",
    color: "rgba(23, 23, 23, 1)"
  },
  typo1: {
    fontFamily: "Quicksand",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "17.5px",
    color: "rgba(120, 113, 108, 1)"
  },
  CardNumberTypo: {
    fontFamily: "Quicksand",
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: "50px",
    color: "rgba(255, 255, 255, 1)"
  },
  CardTextTypo: {
    fontFamily: "Quicksand",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "22.5px",
    color: "rgba(255, 255, 255, 1)",
    textTransform: "capitalize",
    width: "100%"

  },
  typoBox: {
    maxHeight: "100px",
    display: "flex",
    marginLeft: "10px",
    alignItems: "end",
    width: "50%"
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "130px",
    height: "100px",
  }
};

const MainBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "600px",
  gap: "32px",
  padding: "0.8rem"
});

const TypoBox = styled(Box)({
  maxHeight: "100px",
  display: "flex",
  marginLeft: "10px",
  alignItems: "end",
  width: "50%",
  "@media (max-width:960px)": {
    position: "absolute",
    bottom: "10px",
    minWidth: "70%",
    zIndex: 1,
  },
  "@media (min-width:720px)": {
    fontWeight: 700,
    fontSize: "30px",
  },
});

const ImageBox = styled(Box)({
  display: "flex",
  width: "100%",
  "@media (max-width:960px)": {
    display: "flex",
    flexDirection: "column"
  },
  "@media (min-width:720px)": {
    fontWeight: 700,
    fontSize: "30px",
  },
});
const CardBox = styled(Box)({
  maxWidth: "292px",
  height: "200px",
  borderRadius: "8px",
  background: "rgba(23, 23, 23, 1)",
  "@media (max-width:960px)": {
    maxWidth: "167px",
    position: "relative"
  },
  "@media (min-width:720px)": {
    fontWeight: 700,
    fontSize: "30px",
  },

});

const ImgBoxStyle = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "130px",
  height: "100px",
  "@media (max-width:960px)": {
    display: "flex !important",
    justifyContent: "end !important",
    width: "95% !important",
  },
  "@media (min-width:720px)": {
  },
});
// Customizable Area End
