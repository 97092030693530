// Customizable Area Start
import React from "react";

import {
    Box,
    TextField,
    Container,
    Typography,
    FormGroup,
    FormControlLabel,
    Radio,

} from "@mui/material";
import { styled } from "@mui/styles";
import { Formik, FormikTouched } from "formik";
import * as Yup from "yup";
import Checkbox from '@mui/material/Checkbox';
import Navbar from "../../../../packages/components/src/NavBar.web";
import SurveyFormController, {
    Props
} from "./SurveyFormController.web";

export default class SurveyForm extends SurveyFormController {
    constructor(props: Props) {
        super(props);
    }

    isSchema = () => {
        return Yup.object().shape({
            shiftOne: Yup.string().required('*required field'),
            shiftTwo: Yup.string().required('*required field'),
            shiftThree: Yup.string().required('*required field'),
            shiftFour: Yup.string().required('*required field'),
            shiftFive: Yup.string().required('*required field'),
            shiftSix: Yup.string().required('*required field'),
            NormalSeason: Yup.string().required('*required field'),
            HighSeason: Yup.string().required('*required field'),
            region: Yup.string().required('*required field'),
            months: Yup.string().required('*required field'),
            quote: Yup.string().required('*required field'),


            // selection: Yup.number().required('You must select an option.'),

        });
    };

    submit = (values: any, handler: any) => {
        this.setState({
            initialValues: {
                shiftOne: values.shiftOne,
                shiftTwo: values.shiftTwo,
                shiftThree: values.shiftThree,
                shiftFour: values.shiftFour,
                shiftFive: values.shiftFive,
                shiftSix: values.shiftSix,
                NormalSeason: values.NormalSeason,
                HighSeason: values.HighSeason,
                region: values.region,
                months: values.months,
                quote: values.quote


            }
        })
        handler.resetForm();
    }

    renderTextfields = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
        return (
            <>
                <Box style={{ maxWidth: "343px", display: "flex", flexDirection: "column", gap: "16px" }}>
                    <Box>
                        <Typography style={{
                            fontFamily: "Quicksand",
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            borderRadius: "8px",
                            color: "#78716C",
                            display: "flex",
                            textAlign: "left",
                            gap: "10px"

                        }}>
                            <div> 1. </div>
                            <div>What's the duration of each shift in
                                <span style={{ color: "#C7161D" }}> NORMAL SEASON? </span>
                                (in hours)
                            </div>
                        </Typography>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.shiftOne && touched.shiftOne ? (
                                <Typography className="errorclass">
                                    {errors.shiftOne}</Typography>) : null}
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}> <Box sx={{ display: 'flex' }}>
                                <Checkbox  sx={{'&.Mui-checked': { color: "#C7161D" }}} />
                            </Box>
                            <Box sx={webStyle.passwordTypo}> Shift 1 </Box>
                            <TextField
                                sx={{ width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px", backgroundColor: "#F5F5F4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.shiftOne && touched.shiftOne && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",  fontFamily: "Quicksand !important",
                                        },
                                    }),
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="1 to 8 hours"
                                name="shiftOne"
                                data-test-id="passwordId"
                                value={values.shiftOne}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.shiftTwo && touched.shiftTwo ? (
                                <Typography className="errorclass">
                                    {errors.shiftTwo}</Typography>) : null}
                        </Box>
                        <Box style={{ display: "flex",
                             alignItems: "center", gap: "8px" }}>
                            <Box sx={{ display: 'flex' }}>
                                <Checkbox
                                    sx={{
                                        '&.Mui-checked': {   color: "#C7161D",
                                        },
                                    }} />
                            </Box>
                            <Box 
                            sx={webStyle.passwordTypo}>
                                Shift 2
                            </Box>
                            <TextField
                                sx={{ width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#F5F5F4", fontFamily: "Quicksand",
                                    },
                                    ...(errors.shiftTwo && touched.shiftTwo && {
                                        "& fieldset": {
                                            border: "1px solid red !important", borderColor: "#C7161D !important",
                                            fontFamily: "Quicksand !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="1 to 8 hours"
                                name="shiftTwo" data-test-id="passwordId"
                                value={values.shiftTwo}
                                onChange={handleChange}  onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.shiftThree && touched.shiftThree ? (
                                <Typography className="errorclass">
                                    {errors.shiftThree}</Typography>) : null}
                        </Box>
                        <Box style={{ display: "flex",gap: "8px", alignItems: "center" }}>
                            <Box sx={{ display: 'flex' }}>
                                <Checkbox sx={{
                                        '&.Mui-checked': {
                                            color: "#C7161D",},}} />
                            </Box>
                            <Box sx={webStyle.passwordTypo}>Shift 3</Box>
                            <TextField
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "Quicksand",
                                        borderRadius: "8px",
                                        backgroundColor: "#F5F5F4",
                                    },
                                    ...(errors.shiftThree && touched.shiftThree && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            fontFamily: "Quicksand !important",
                                            borderColor: "#C7161D !important",
                                        },
                                    }),
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",
                                    },
                                }}
                                placeholder="1 to 8 hours"
                                name="shiftThree"
                                data-test-id="passwordId"
                                value={values.shiftThree}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>

                    <hr style={webStyle.hrClasss} />
                    <Box>
                        {this.renderTextFieldsSecond(errors, touched, values, handleChange, handleBlur)}
                    </Box>
                    <hr style={webStyle.hrClasss} />
                    {this.renderTextFieldsThird(errors, touched, values, handleChange, handleBlur)}
                    <hr style={{
                        width: "100%",
                        color: "#D6D3D1",
                        marginTop: "2rem"
                    }} />

                </Box >
            </>
        )

    }

    renderTextFieldsSecond = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
        return (

            <>
                <Box style={{ maxWidth: "343px", gap: "16px", display: "flex", flexDirection: "column" }}>
                    <Box>
                        <Typography style={{
                            fontFamily: "Quicksand",
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            borderRadius: "8px",
                            color: "#78716C",
                            display: "flex",
                            textAlign: "left",
                            gap: "10px"

                        }}>
                            <div> 2. </div>
                            <div>What's the duration of each shift in
                                <span style={{ color: "#C7161D" }}> HIGH SEASON </span>
                                ?(in hours)
                            </div>
                        </Typography>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.shiftFour && touched.shiftFour ? (
                                <Typography className="errorclass">
                                    {errors.shiftFour}</Typography>) : null}
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Box sx={{ display: 'flex' }}>
                                <Checkbox
                                    sx={{
                                        '&.Mui-checked': {
                                            color: "#C7161D",
                                        },
                                    }} />
                            </Box>
                            <Box sx={webStyle.passwordTypo}>
                                Shift 1
                            </Box>
                            <TextField
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#F5F5F4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.shiftFour && touched.shiftFour && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",
                                            fontFamily: "Quicksand !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="1 to 8 hours"
                                name="shiftFour"
                                data-test-id="passwordId"
                                value={values.shiftFour}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.shiftFive && touched.shiftFive ? (
                                <Typography className="errorclass">
                                    {errors.shiftFive}</Typography>) : null}
                        </Box>
                        <Box 
                        style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Box sx={{ display: 'flex' }}>
                                <Checkbox
                                    sx={{
                                        '&.Mui-checked': 
                                        {color: "#C7161D"},}} />
                            </Box>
                            <Box sx={webStyle.passwordTypo}>Shift 2</Box>
                            <TextField
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#F5F5F4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.shiftFive && touched.shiftFive && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",
                                            fontFamily: "Quicksand !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="1 to 8 hours"
                                name="shiftFive"
                                data-test-id="passwordId"
                                value={values.shiftFive}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.shiftSix && touched.shiftSix ? (
                                <Typography className="errorclass">
                                    {errors.shiftSix}</Typography>) : null}
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Box sx={{ display: 'flex' }}>
                                <Checkbox
                                    sx={{
                                        '&.Mui-checked': {
                                            color: "#C7161D",
                                        },
                                    }} />
                            </Box>
                            <Box sx={webStyle.passwordTypo}>
                                Shift 3
                            </Box>
                            <TextField
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#F5F5F4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.shiftSix && touched.shiftSix && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",
                                            fontFamily: "Quicksand !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="1 to 8 hours"
                                name="shiftSix"
                                data-test-id="passwordId"
                                value={values.shiftSix}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </Box>

            </>

        )
    }

    renderTextFieldsThird = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any) => {
        return (

            <>
                <Box style={{ maxWidth: "343px", gap: "16px", display: "flex", flexDirection: "column" }}>
                    <Box>
                        <Typography style={{
                            fontFamily: "Quicksand",
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "24px",
                            borderRadius: "8px",
                            color: "#78716C",
                            display: "flex",
                            textAlign: "left",
                            gap: "10px"

                        }}>
                            <div> 3. </div>
                            <div>How many forklift hours per shift?</div>

                        </Typography>
                    </Box>
                    <Box className="custom-text-field">
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.NormalSeason && errors.HighSeason && touched.NormalSeason && touched.HighSeason ? (
                                <Typography className="errorclass">
                                    {errors.NormalSeason}</Typography>) : null}
                        </Box>
                        <Box style={{ display: "flex", gap: "8px", flexDirection: "column", color: "#78716C" }}>
                            <Box sx={webStyle.passwordTypo2}>
                                Normal Season
                            </Box>
                            <TextField
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#E7E5E4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.NormalSeason && touched.NormalSeason && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="Answere"
                                name="NormalSeason"
                                data-test-id="passwordId"
                                value={values.NormalSeason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>

                    <Box className="custom-text-field">
                        <Box style={{ display: "flex", gap: "8px", flexDirection: "column" }}>
                            <Box sx={webStyle.passwordTypo2}>
                                High Season
                            </Box>
                            <TextField
                                sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        backgroundColor: "#E7E5E4",
                                        fontFamily: "Quicksand",
                                    },
                                    ...(errors.HighSeason && touched.HighSeason && {
                                        "& fieldset": {
                                            border: "1px solid red !important",
                                            borderColor: "#C7161D !important",
                                            fontFamily: "Quicksand !important",
                                        },
                                    }),

                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "none",

                                    },
                                }}
                                placeholder="Answere"
                                name="HighSeason"
                                data-test-id="passwordId"
                                value={values.HighSeason}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                </Box>

            </>

        )
    }

    renderSelectOption = (errors: any, touched: FormikTouched<any>, values: any, handleChange: any, handleBlur: any, setFieldValue: any) => {
        return (
            <>
                <Box style={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "343px" }}>
                    <Typography style={{
                        fontFamily: "Quicksand",
                        fontSize: "18px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        borderRadius: "8px",
                        color: "#78716C",
                        display: "flex",
                        textAlign: "left",
                        gap: "10px"

                    }}>
                        <div> 4. </div>
                        <div>Which months are considered the normal season?</div>

                    </Typography>
                    <Box style={{
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        {errors.months && touched.months ? (
                            <Typography className="errorclass">
                                {errors.months}</Typography>) : null}
                    </Box>
                    <CheckBoxMainBox>
                        {this.state.staticMonthData.map((data, index) => {
                            return (
                                <>

                                    <FormGroup key={index}
                                        data-test-id="testcase1"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    data-test-id="testcase1"
                                                    checked={this.isChecked(data.name, values.months)}
                                                    onChange={this.handleCheckboxChange.bind(this, data.name, setFieldValue, values.months)}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: "#C7161D",
                                                        },
                                                    }}
                                                />
                                            }
                                            name='months'
                                            label={
                                                <Typography style={webStyle.checkBoxLabel}>
                                                    {data.name}
                                                </Typography>
                                            }
                                        />
                                    </FormGroup>
                                </>

                            )
                        })}
                    </CheckBoxMainBox>
                    <hr style={webStyle.hrClasss} />


                    <Typography style={{
                        fontFamily: "Quicksand",
                        fontSize: "18px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        borderRadius: "8px",
                        color: "#78716C",
                        display: "flex",
                        textAlign: "left",
                        gap: "10px"

                    }}>
                        <div> 5. </div>
                        <div>Region</div>

                    </Typography>

                    <CheckBoxMainBox2>
                        <Box style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            {errors.region && touched.region ? (
                                <Typography className="errorclass">
                                    {errors.region}</Typography>) : null}
                        </Box>
                        <FormGroup
                            row
                            onChange={handleChange}
                            onBlur={handleBlur}

                        >
                            <FormControlLabel
                                control={<Checkbox checked={values.region.includes("UnitedStates")} sx={{
                                    '&.Mui-checked': {
                                        color: "#C7161D",
                                    },
                                }} />}
                                name='region'
                                value="UnitedStates"
                                label={
                                    <Typography style={webStyle.checkBoxLabel2}>
                                        United States
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                control={<Checkbox checked={values.region.includes("Mexico")} sx={{
                                    '&.Mui-checked': {
                                        color: "#C7161D",
                                    }
                                }} />}
                                name='region'
                                value="Mexico"
                                label={
                                    <Typography style={webStyle.checkBoxLabel2}>
                                        Mexico
                                    </Typography>
                                }
                            />
                        </FormGroup>
                    </CheckBoxMainBox2>

                    <hr style={webStyle.hrClasss} />

                    <Typography style={{
                        fontFamily: "Quicksand",
                        fontSize: "18px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        borderRadius: "8px",
                        color: "#78716C",
                        display: "flex",
                        textAlign: "left",
                        gap: "10px"

                    }}>
                        <div> 6. </div>
                        <div>Do you want quote for:</div>

                    </Typography>

                    <Box style={{
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        {(!this.state.selectedValue && !this.state.selectedValue2) && touched.quote ? (
                            <Typography className="errorclass">
                                {errors.quote}</Typography>) : null}
                    </Box>

                    <Box style={{ width: "95%", }}>

                        <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                            <CustomRadio
                                checked={this.state.selectedValue}
                                onChange={this.handleChange}
                                value={values.quote}
                                name="customradio"
                                inputProps={{ 'aria-label': 'A' }}
                                icon={<span className="spanstyle"></span>}

                            />
                            <Typography style={webStyle.radioTypo}>Existing Truck / Replacement Battery / Charger</Typography>
                        </Box>

                        <Box style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>

                            <CustomRadio
                                checked={this.state.selectedValue2}
                                onChange={this.handleChange}
                                value={values.quote}
                                name="custom-radio"
                                inputProps={{ 'aria-label': 'B' }}
                                icon={<span className="spanstyle"></span>}
                            />
                            <Typography style={webStyle.radioTypo}>New Truck /  New Battery</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <hr style={{
                            width: "100%",
                            color: "#D6D3D1",
                            marginTop: "2rem"
                        }} />
                    </Box>
                </Box >

            </>
        )

    }



    render() {
        return (
            <>
                <Navbar navigation={null} id="" />

                <Container>
                    <ClientDetailBox>
                        <Box className="custom-text-field">
                            <Typography style={{
                                fontFamily: "Quicksand",
                                fontSize: "32px",
                                fontWeight: 700,
                                lineHeight: "40px",
                                textAlign: "left"
                            }}>Survey</Typography>
                        </Box>
                        <Formik
                            data-test-id="userboxForm"
                            initialValues={this.state.initialValues}
                            validationSchema={this.isSchema()}
                            onSubmit={this.submit}
                        >
                            {({ values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue }) => {
                                return (

                                    <Box className="formclass" >
                                        {this.renderTextfields(errors, touched, values, handleChange, handleBlur)
                                        }
                                        {this.renderSelectOption(errors, touched, values, handleChange, handleBlur, setFieldValue)}


                                        <Box style={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "343px", width: "100%" }}>
                                            <button type="submit" className="btn1" data-test-id="delete-account1" onClick={() => handleSubmit()} > Continue</button>
                                            <button className="btn2" data-test-id="delete-account2">Back</button>
                                        </Box>
                                    </Box>
                                )
                            }}
                        </Formik>
                    </ClientDetailBox >
                </Container>
            </>
        );
    }
}

const webStyle = {
    passwordTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color: "#78716C",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    passwordTypo2: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#C7161D",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    logoutButton: {
        border: "1px solid #57534E",
        color: "#ffffff",
        textTransform: "capitalize",
        display: "flex",
        justifyContent: "flex-start",
        gap: "16px",
        padding: "8px 16px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
    },
    createClientBtn: {
        background: "#C7161D",
        display: "flex",
        gap: "15px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        borderRadius: "8px",
        textTransform: "capitalize",
    },
    previousQoutesbtn: {
        display: "flex", gap: "15px",
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        lineHeight: "20px",
        textTransform: "capitalize",
    },
    analyticsbtn: {
        fontFamily: "Quicksand",
        display: "flex", gap: "15px",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20px",
        textTransform: "capitalize",
        borderRadius: "8px",
    },
    CheckBoxStyle: {
        width: "16px",
        height: "16px",
        borderRadius: "4px",
        border: "1px solid #78716C",
    },
    checkBoxLabel: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "13.79px",
        color: "#171717"
    },
    checkBoxLabel2: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#78716C"
    },
    radioTypo: {
        fontFamily: "Quicksand",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#78716C",
        width: "278px"
    },
    hrClasss: {
        width: "100%",
        color: "#D6D3D1",
        margin: "2rem 0rem"

    }

};

const CheckBoxMainBox = styled(Box)({
    height: "252px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column"
})
const CheckBoxMainBox2 = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    flexDirection: "column"
})
const ClientDetailBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "3rem",
    gap: "48px",
    "& .formclass": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "48px",
    },
    "& .errorclass": {
        color: "#C7161D",
        fontFamily: "Quicksand",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left"
    },
    "& .custom-text-field": {
        width: "343px",
        display: "flex",
        gap: "8px",
        flexDirection: "column"
    },
    "& .btn1": {
        color: "White",
        backgroundColor: "#C7161D",
        borderRadius: "8px",
        height: "55px",
        textTransform: "capitalize",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        border: "none"
    },
    "& .btn2": {
        color: "#171717",
        border: "1px solid #171717",
        borderRadius: "8px",
        height: "55px",
        textTransform: "capitalize",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        textAlign: "center",
        backgroundColor: "white"
    },

})

const CustomRadio = styled(Radio)(({ theme }) => ({
    '&.MuiRadio-root': {
        color: 'rgba(231, 229, 228, 1) !important',
    },
    '&.Mui-checked': {
        color: '#C7161D !important',
    },
    '& .MuiSvgIcon-root': {

    },
    '&.Mui-checked .MuiSvgIcon-root': {
        borderRadius: '50%',

    },
    '&:hover': {
        backgroundColor: 'transparent',
    },
    "& .spanstyle": {
        width: "16px !important",
        height: "16px !important",
        background: "rgba(231, 229, 228, 1) !important",
        border: "1px solid rgba(168, 162, 158, 1) !important",
        borderRadius: "50%",
        position: "relative"
    }
}));

// Customizable Area End
