import React from "react";
// Customizable Area Start
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from "@mui/styles";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PreviousQuotesController, {
    Props,
} from "./PreviousQuotesController.web";
import { Search } from "@mui/icons-material";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import {previousQuotesMobileImg } from "./assets";
import NavigationMenuBar from "../../../../packages/components/src/NavBar.web";

// Customizable Area End

export default class PreviousQuotes extends PreviousQuotesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>  
            <NavigationMenuBar navigation={this.props.navigation} navigateToAnyPage={this.navigateToAnyPage} id="" />
                <NavigationMenu 
                 navigation={null}
                 id=""
                 srcURL={previousQuotesMobileImg}
                 />
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <MainBox>
                        <Box sx={webStyle.headerBox}>
                            <Typography sx={webStyle.headerTypo}>Previous quotes</Typography>
                        </Box>
                        <Box>
                            <Typography sx={webStyle.typo1}>Review your past quotes at any time. Find the details of your previous requests.</Typography>
                        </Box>
                        <SearchAndFilter >

                            <CustomSearchBox
                                fullWidth
                                data-test-id="btn_change"
                                id="search"
                                name="search"
                                variant="outlined"
                                placeholder="Search"
                                onChange={this.getSearchData}
                                value={this.state.search}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="search"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                sx={webStyle.buttontypo}

                            >
                                Filter <FilterAltOutlinedIcon /></Button>
                        </SearchAndFilter>
                        <Box style={{ display: "flex", flexDirection: "column" }}>

                            <hr style={{ color: "#D6D3D1", width: "100%" }} />
                            <CardBox>
                                <Box style={{ display: "flex", flexDirection: "column", gap: "5px", marginLeft: "10px" }}>
                                    <Typography style={webStyle.CardBoxTypotext1}>Client</Typography>
                                    <Typography style={webStyle.CardBoxTyponumber1}>#123456</Typography>
                                </Box>

                                <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                    <Typography style={webStyle.CardBoxTyponumber1}>Warehouse HQ</Typography>
                                    <Typography style={webStyle.CardBoxTyponumber1}>08/20/2024</Typography>
                                </Box>
                                <Box>
                                    <KeyboardArrowRightIcon style={{ color: "white", height: "30px", width: "30px" }} />
                                </Box>
                            </CardBox>
                        </Box>

                    </MainBox>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    headerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    headerTypo: {
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        textalign: "left",

    },
    buttontypo: {
        textTransform: "capitalize",
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "24px",
        backgroundColor: "rgba(231, 229, 228, 1) !important",
        color: "rgba(23, 23, 23, 1)",
        height: "52px"
    },
    typo1: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "17.5px",
        color: "rgba(120, 113, 108, 1)"
    },
    CardBoxTypotext1: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        color: "white"
    },
    CardBoxTyponumber1: {
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "15px",
        color: "white",
        textAlign: "end " as "end"
    },
    CardNumberTypo: {
        fontFamily: "Quicksand",
        fontSize: "40px",
        fontWeight: 700,
        lineHeight: "50px",
        color: "rgba(255, 255, 255, 1)"
    },
    CardTextTypo: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        color: "rgba(255, 255, 255, 1)",
        textTransform: "capitalize",
        width: "100%"
    },
    typoBox: {
        maxHeight: "100px",
        display: "flex",
        marginLeft: "10px",
        alignItems: "end",
        width: "50%"
    },
    imgBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "130px",
        height: "100px",
    }
};

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    gap: "32px",
    padding: "0.8rem"
});
const SearchAndFilter = styled(Box)({
    display: "flex",
    width: "100%",
    gap: "5px",
    "@media (max-width:960px)": {
        display: "flex",
    },
    "@media (min-width:720px)": {
    },
});
const CardBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "600px",
    height: "80px",
    borderRadius: "8px",
    cursor: "pointer",
    background: "rgba(23, 23, 23, 1)",
    "@media (max-width:960px)": {
        width: "100%",
    },
    "@media (min-width:720px)": {
    },
});

const CustomSearchBox = styled(TextField)({
    background: "rgba(231, 229, 228, 1)",
    fontFamily: "Quicksand",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important"
    },
    "& .MuiInputBase-root": {
        fontFamily: 'Quicksand',
    },
    "@media (max-width:960px)": {
    },
    "@media (min-width:720px)": {
    },
});

// Customizable Area End
