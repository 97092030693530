import React from "react";
// Customizable Area Start
import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
  TextField,
  Button,
  IconButton,
  } from "@mui/material";
import {CheckCircleOutlineRounded,HighlightOff,CheckCircle,Cancel,VisibilityOutlined,VisibilityOffOutlined} from '@mui/icons-material';
import {belowBannerImage,aboveBannerImage } from "./assets";

const theme = createTheme({
  typography:{
    fontFamily:"Quicksand"
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "#E7E5E4",
            color: "#171717",
            borderRadius: 10,
            fontSize: 16,
            fontWeight: 500,
            height: "56px",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          },
          '& input[type=number]': {
            '-moz-appearance': 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
          }
          ,
          "& input:-webkit-autofill": {
            transition:
              "background-color 0s 600000s, color 0s 600000s !important",
          },
          "& input": {
            paddingBlock: 16,
            paddingInline: 18,
            color: "#171717"
          },
          "& .MuiInputBase-root.MuiOutlinedInput-root:hover:not(.Mui-focused)":
          {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
              borderWidth: 2,
            },
          },
        },
      },
    },
  }
})
const conditionFunc = (condition: boolean, trueValue: string, falseValue: string) => {
  if(condition){
    return trueValue
  }else{
    return falseValue
  }
}
const conditionIcon=(condition:boolean,firstIcon:React.ReactNode,secondIcon:React.ReactNode)=>{
  if(condition){
    return firstIcon
  }else{
    return secondIcon
  }
}
const instructionConditionJSX=(firstCondition:boolean,secondCondition:boolean)=>{
  if (firstCondition) {
    return <Box sx={styles.instructionsIconBox}>
      <Box sx={styles.smallCircle} />
    </Box>
  } else if (secondCondition) {
    return <Box sx={styles.instructionsIconBox}>
      <CheckCircle sx={styles.circleIcon} />
    </Box>
  } else {
    return <Box sx={styles.instructionsIconBox}>
      <Cancel sx={styles.closeIcon} />
    </Box>
  }
}
const handleInstructionsJSX = (password: string, instructions: { regex: { test: (value: string) => boolean }, text: string }[], isPasswordMismatch: boolean) => {
  return <Box sx={styles.insideInnerMainBox}>
    <Box sx={styles.insideInnerBox}>
      {instructions.map((instruction: { regex: { test: (value: string) => boolean }, text: string }) => (
        <Box sx={styles.instructionBox} key={instruction.text}>
          {instructionConditionJSX(password.length === 0 || isPasswordMismatch, instruction.regex.test(password)) }
          <Typography sx={styles.instructionText}>{instruction.text}</Typography>
        </Box>
      ))}
      <Box sx={styles.instructionBox}>
         {instructionConditionJSX(password.length === 0 || isPasswordMismatch , password.length >= 8 )}
        <Typography sx={styles.instructionText}>{configJSON.minEightLength}</Typography>
      </Box>
    </Box>
  </Box>
}
// Customizable Area End

import ForgotPasswordController, 
{ Props,configJSON } from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  render() {
    // Customizable Area Start
    const {accountType,displayAlert,forgotPasswordData,passwordEyeOpen,confirmPasswordOpen,passwordType}=this.state
    const mainCoreJSX = () => {
      switch (true) {
        case accountType === "email" || accountType === "sms":
          return <>
            <Typography sx={styles.blockHeading} data-testid="forgot-password">
              {configJSON.forgotPwd}
            </Typography>
            <Typography data-testid="heading-below-text" sx={styles.headingBelowText}>{configJSON.weWill} {conditionFunc(accountType === "email", configJSON.emailYou, configJSON.sendCodeToPhone)} </Typography>
            <Box>
              <Typography sx={styles.label}>
                {conditionFunc(accountType === "sms", "Phone number", "Email")}
              </Typography>
              <TextField data-testid="email-input" type={conditionFunc(accountType === "email", "email", "number")} value={conditionFunc(accountType === "email", forgotPasswordData.email, forgotPasswordData.phoneNumber)} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event, conditionFunc(accountType === "email", "email", "phoneNumber"))} placeholder={conditionFunc(accountType === "sms", configJSON.yourPhoneNumber, configJSON.yourEmail)} fullWidth />
              <Box sx={styles.navigateTextBox(false)}>
                <Typography data-testid="navigate-text" component={"a"} onClick={() => this.handleSetActionType(accountType !== "email" ? "email" : "sms")} sx={styles.navigateText}>{configJSON.tryWith} {conditionFunc(accountType !== "email", configJSON.email, configJSON.phoneNumber)}</Typography>
              </Box>
            </Box>
          </>
        case accountType === "otp":
          return <>
            <Typography sx={styles.blockHeading} data-testid="enter-code">{configJSON.enterCode} </Typography>
            <Typography sx={styles.headingBelowText}>{configJSON.pleaseEnterTheCode}</Typography>
            <Box sx={styles.insideInnerMainBox}>
              <Box sx={styles.otpFieldBox}>
                {
                  forgotPasswordData.otp.map((value, index: number) => (
                    <TextField data-testid="otp-field" sx={styles.otpField} placeholder={configJSON.hyphen} key={index} type="text" inputProps={{ maxLength: 1, style: { textAlign: "center" } }} value={value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOtpOnChange(event, index)} onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => this.handleOtpKeyDown(event, index)} inputRef={(input) => this.otpInputRefs[index] = input} />
                  ))
                }
              </Box>
            </Box>
            <Box sx={styles.navigateTextBox(true)}>
              <Typography data-testid="resend-otp-anchor" component={"a"} onClick={this.handleResentTheCode} sx={styles.navigateText}>{configJSON.resendCode}</Typography>
            </Box>
          </>
        case accountType === "reset":
          return <>
            <Typography sx={styles.blockHeading} >{configJSON.resetPwd}</Typography>
            <Box >
              <Typography sx={styles.passwordLabel}> {configJSON.newPwd}  </Typography>
              <TextField data-testid="password-input" InputProps={{ endAdornment: <IconButton onClick={() => this.handlePasswordEye(1)}>{conditionIcon(!passwordEyeOpen , <VisibilityOffOutlined /> , <VisibilityOutlined />)}</IconButton> }} type={conditionFunc(passwordEyeOpen, "text", "password")} value={forgotPasswordData.password} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event, "password")} onFocus={() => this.handleFocus(1)} placeholder={configJSON.yourPwd} fullWidth />
            </Box>
            <Box >
              <Typography sx={styles.passwordLabel}> {configJSON.confirmPwd} </Typography>
              <TextField data-testid="confirm-password-input" type={conditionFunc(confirmPasswordOpen, "text", "password")} InputProps={{ endAdornment: <IconButton onClick={() => this.handlePasswordEye(2)}>{conditionIcon(!confirmPasswordOpen , <VisibilityOffOutlined /> , <VisibilityOutlined />)}</IconButton> }} value={forgotPasswordData.confirmPassword} onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOnChange(event, "confirmPassword")} onFocus={() => this.handleFocus(2)} placeholder={configJSON.yourPwd} fullWidth />
            </Box>
            {handleInstructionsJSX(passwordType === 1 ? forgotPasswordData.password : forgotPasswordData.confirmPassword, this.passwordInstructions, passwordType === 3)}
          </>
        default: {
          return <>
            <Typography sx={styles.blockHeading}>
              {configJSON.successMessage}
            </Typography>
            <Typography sx={styles.passwordLabel}>{configJSON.logInToYourAccount}</Typography>
          </>
        }
      }
    }
     return (
       <ThemeProvider theme={theme}>
         <CssBaseline />
         <Box sx={styles.mainBox}>
           <Box sx={styles.bannerMainBox}>
             <Box data-testid="forgot-banner-image" component={"img"} src={belowBannerImage} sx={styles.belowBannerImage} />
             <Box component={"img"} src={aboveBannerImage} sx={styles.aboveBannerImage} />
           </Box>
           <Box sx={styles.contentMainBox}>
             <Box sx={styles.contentInnerBox} >
               <Typography data-testid="alert-message" sx={styles.errorText(Boolean(displayAlert.message),displayAlert.isSuccess)}>
                 <IconButton data-testid="remove-error-button" onClick={this.handleRemoveError}>
                   {conditionIcon(displayAlert.isSuccess , <CheckCircleOutlineRounded /> , < HighlightOff />)}
                 </IconButton>
                 {displayAlert.message}
               </Typography>
               {mainCoreJSX()}
               <Button data-testid="submit-button" variant="contained" onClick={this.handleSubmit} sx={styles.button(this.handleGetValue())}>{this.handleButtonText()}</Button>
             </Box>
           </Box>
         </Box>
       </ThemeProvider>
    )
    // Customizable Area End
  }
}


// Customizable Area Start
const styles = {
  instructionsIconBox: {
    width: "18px",
    height: "18px",
    display: "grid",
    placeItems: "center"
  },
  mainBox: {
    display: "flex",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column"
    },
  },
  bannerMainBox: {
    position: "relative",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "45%"
    },
    [theme.breakpoints.up("xs")]: {
      height: "170px",
      width: "100%"
    },
  },
  belowBannerImage: {
    position: "absolute",
    zIndex: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "15px"
    },
    [theme.breakpoints.up("xs")]: {
      borderRadius: "15px 15px 0px 0px"
    },
  },
  aboveBannerImage: {
    position: "absolute", zIndex: 2,
    width: "100%", height: "100%",
    objectFit: "cover",
    objectPosition: "top",
    [theme.breakpoints.up("md")]: {
      borderRadius: "15px"
    },
    [theme.breakpoints.up("xs")]: {
      borderRadius: "15px 15px 0px 0px"
    },
  },
  contentMainBox: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      height: "100%",
      width: "55%",
      alignItems: "center"
    },
    [theme.breakpoints.up("xs")]: {
      height: "100%",
      width: "100%",
      alignItems: "start",
    },
  },
  contentInnerBox: {
    [theme.breakpoints.up("md")]: { width: 360 },
    [theme.breakpoints.up("sm")]: { width: 350 },
    [theme.breakpoints.up("xs")]: { width: 320 }
  },
  errorText: (isError: boolean, isSuccess: boolean) => ({
    visibility: isError ? "visible" : "hidden",
    fontWeight: 700,
    fontSize: "12px",
    textAlign: "center",
    color: isSuccess ? "#059669" : "#DC2626",
    bgcolor: "#F5F5F4",
    width: "100%",
    height: "34px",
    p: "16px 8px",
    my: 2.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "md")]: { mt: "5%", },
    "& .MuiSvgIcon-root": { color: isSuccess ? "#059669" : "DC2626" }
  }),
  blockHeading: {
    fontWeight: 700,
    fontSize: 32,
    color: "#171717"
  },
  headingBelowText: {
    color: "#78716C",
    fontWeight: 400,
    fontSize: "14px"
  },
  label: {
    color: "#78716C",
    fontWeight: 700,
    mt: "10%"
  },
  passwordLabel: {
    color: "#78716C",
    fontWeight: 700,
    mt: "5%"
  },
  navigateTextBox: (isCenter: boolean) => ({
    display: "flex",
    justifyContent: isCenter ? "center" : "end",
    mt: "5%"
  }),
  navigateText: {
    fontWeight: 700,
    borderBottom: "1px solid",
    pb: 0.5,
    fontSize: 12
  },
  button: (isExist: boolean) => ({
    "&.MuiButton-root": {
      bgcolor: isExist ? "#C7161D" : "#A8A29E",
      width: "100%",
      p: 2,
      mt: "8%",
      borderRadius: "8px",
      textTransform: "none",
      fontSize: 18,
      fontWeight: 700
    }
  }),
  circleIcon: {
    color: "#10B981",
    fontSize: "18px"
  },
  closeIcon: {
    color: "#DC2626",
    fontSize: "18px"
  },
  smallCircle: {
    height: "8px",
    width: "8px",
    bgcolor: "#A8A29E",
    borderRadius: 30
  },
  instructionBox: {
    height: "22px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
    mb: "2%"
  },
  instructionText: {
    color: "#171717",
    fontWeight: 400,
    fontSize: "14px"
  },
  insideInnerMainBox: {
    display: "flex",
    justifyContent: "center",
    mt: "8%"
  },
  insideInnerBox: {
    [theme.breakpoints.up("xs")]: {
      width: 300
    }
  },
  otpFieldBox: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    my: "2%",
    height: "70px",
    [theme.breakpoints.up("md")]: {
      width: 340
    },
    [theme.breakpoints.up("sm")]: {
      width: 320
    },
    [theme.breakpoints.up("xs")]: {
      width: 300
    }
  },
  otpField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
      height: "56px",
      width: "56px"
    }
  }
}  
// Customizable Area End